import React from 'react';
import moment from 'moment';

import { ITimelineRangeSettingsState } from 'components/Timeline/interfaces/timelineRangeSettingState.interface';
import { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import {
  calculateIncrementResolution,
  calculateTimeLineEnd,
} from 'components/Timeline/helper/timelineSettings.helper';

type TPickerSelectionType = 'start' | 'end';

interface IUseTimelineControlsReturn {
  handleOnClickLeftArrow: (pickerType: TPickerSelectionType) => void;
  handleOnClickRightArrow: (pickerType: TPickerSelectionType) => void;
  handleOnClickToday: () => void;
  handleDateChange: (date: Date, pickerType: TPickerSelectionType) => void;
  handleSetTimelineSize: (newTimelineSizeValue: TTimelineSize) => void;
}

interface IUseTimelineControlsProps<T> {
  setTimelineSettings: React.Dispatch<React.SetStateAction<T>>;
}

const useTimelineRangeControls = <T extends ITimelineRangeSettingsState>({
  setTimelineSettings,
}: IUseTimelineControlsProps<T>): IUseTimelineControlsReturn => {
  const correctDateDifference = (currentState: T) => {
    if (currentState.endDate.isBefore(currentState.startDate)) {
      currentState.endDate = currentState.startDate
        .clone()
        .add(1, 'day')
        .endOf('day');
    }
  };

  const handleDateChange = (
    date: Date,
    pickerType: TPickerSelectionType
  ): void => {
    setTimelineSettings((prevState) => {
      const currentState = { ...prevState };

      if (pickerType === 'start') {
        currentState.startDate = moment(date).startOf('day');
      } else {
        currentState.endDate = moment(date).endOf('day');
      }

      correctDateDifference(currentState);

      return currentState;
    });
  };

  const handleOnClickLeftArrow = (pickerType: TPickerSelectionType): void => {
    setTimelineSettings((prevState) => {
      const currentState = { ...prevState };

      if (pickerType === 'start') {
        currentState.startDate
          .subtract(1, calculateIncrementResolution(currentState.timelineSize))
          .startOf('day');
      } else {
        currentState.endDate
          .subtract(1, calculateIncrementResolution(currentState.timelineSize))
          .endOf('day');
      }

      correctDateDifference(currentState);

      return currentState;
    });
  };

  const handleOnClickRightArrow = (pickerType: TPickerSelectionType): void => {
    setTimelineSettings((prevState) => {
      const currentState = { ...prevState };

      if (pickerType === 'start') {
        currentState.startDate
          .add(1, calculateIncrementResolution(currentState.timelineSize))
          .startOf('day');
      } else {
        currentState.endDate
          .add(1, calculateIncrementResolution(currentState.timelineSize))
          .endOf('day');
      }

      correctDateDifference(currentState);

      return currentState;
    });
  };

  const handleOnClickToday = (): void => {
    setTimelineSettings((prevState) => {
      return {
        ...prevState,
        startDate: moment().startOf('day'),
        endDate: moment()
          .add(1, calculateIncrementResolution(prevState.timelineSize))
          .endOf('day'),
      };
    });
  };

  const handleSetTimelineSize = (newTimelineSizeValue: TTimelineSize): void => {
    setTimelineSettings((prevState) => {
      const currentState = { ...prevState, timelineSize: newTimelineSizeValue };

      const endDate = calculateTimeLineEnd(
        newTimelineSizeValue,
        prevState.startDate
      );

      currentState.endDate = endDate.endOf('day');

      return currentState;
    });
  };

  return {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleSetTimelineSize,
    handleOnClickToday,
    handleDateChange,
  };
};

export default useTimelineRangeControls;
