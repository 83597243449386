import { FC } from 'react';
import { AvatarStatusHighlight } from 'team-hero-ui';

import useGetImageUrl from 'hooks/useGetImageUrl.hook';
import { useElementSize } from 'hooks/useElementSize';
import type { IContact } from 'interfaces/Contact.interface';
import type { IContactSummaryProps } from './ContactSummary.type';
import {
  ContactSummaryMoreIndicatorStyled,
  ContactSummaryWrapperStyled,
} from './ContactSummary.styled';
import { useGetContactItemQuery } from 'services/teamHeroApi.service';
import { calculateContactItemsMaxItems } from './ContactSummary.helper';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';

interface ILeftLegendContact {
  contactIri: string;
  contactToCompare?: IContact | null;
  onChooseContact?: (contact: IContact) => void;
}

const LeftLegendContact: FC<ILeftLegendContact> = ({
  contactIri,
  contactToCompare,
  onChooseContact,
}) => {
  const { data: contactData } = useGetContactItemQuery({
    id: getContactIdFromIriOrContact(contactIri),
  });

  const { imageUrls } = useGetImageUrl({
    images: contactData?.avatar,
    thumbnailSize: 'small',
  });
  const imgUrl = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  const handleHighlight = () => {
    if (contactData && onChooseContact) {
      onChooseContact(contactData);
    }
  };

  return (
    <AvatarStatusHighlight
      image={imgUrl}
      isHighlighted={
        getContactIdFromIriOrContact(contactIri) === contactToCompare?.id
      }
      onSetHighlightContact={handleHighlight}
      firstName={contactData?.firstName}
      lastName={contactData?.lastName}
    />
  );
};

const ContactSummary: FC<IContactSummaryProps> = ({
  contacts,
  keyIdentifier,
  contactToCompare,
  onChooseContact,
}) => {
  const [avatarWrapperRef, { width: wrapperWidth, height: wrapperHeight }] =
    useElementSize();

  const contactsLimit = calculateContactItemsMaxItems(
    40,
    35,
    wrapperHeight,
    wrapperWidth
  );

  return (
    <ContactSummaryWrapperStyled ref={avatarWrapperRef}>
      {contacts.slice(0, contactsLimit).map((contact) => (
        <LeftLegendContact
          key={`${keyIdentifier}-${contact.contact}`}
          contactIri={contact.contact}
          contactToCompare={contactToCompare}
          onChooseContact={onChooseContact}
        />
      ))}
      {contacts.slice(contactsLimit).length > 0 && (
        <ContactSummaryMoreIndicatorStyled>
          +{contacts.slice(contactsLimit).length}
        </ContactSummaryMoreIndicatorStyled>
      )}
    </ContactSummaryWrapperStyled>
  );
};

export default ContactSummary;
