import { useCallback, useState } from 'react';

import type { IContact } from 'interfaces/Contact.interface';

interface IUseContactCompareReturn {
  contactToCompare: IContact | null;
  toggleCompareWithContact: (toggleContact: IContact) => void;
}

export const useContactCompare = (): IUseContactCompareReturn => {
  const [contactToCompare, setContactToCompare] = useState<IContact | null>(
    null
  );

  const toggleCompareWithContact = useCallback(
    (toggleContact: IContact) => {
      if (contactToCompare && toggleContact.id === contactToCompare.id) {
        setContactToCompare(null);
      } else {
        setContactToCompare(toggleContact);
      }
    },
    [contactToCompare]
  );

  return {
    contactToCompare,
    toggleCompareWithContact,
  };
};
