import { TUserRole } from 'interfaces/Role.interface';

type TRequiredUserRolesConfig = {
  [key in
    | 'changeEmail'
    | 'passwordUpdate'
    | 'heroLogin'
    | 'application'
    | 'changePassword'
    | 'clientLogin']: TUserRole[];
};

export const requiredUserRolesConfig: TRequiredUserRolesConfig = {
  changeEmail: ['ROLE_HERO_LOGIN'],
  passwordUpdate: ['ROLE_USER_CHANGE_PASSWORD', 'ROLE_HERO_LOGIN'],
  changePassword: ['ROLE_HERO_LOGIN'],
  heroLogin: ['ROLE_HERO_LOGIN'],
  application: ['ROLE_APPLICANT'],
  clientLogin: ['ROLE_CLIENT'],
};
