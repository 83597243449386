import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';

const { flexFn } = Utils;

export const TimetrackingStatusStyled = styled(CommonCellContentStyled)`
  ${flexFn()}
`;
