import { TFunction } from 'i18next';

import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { IRating } from 'interfaces/Rating.interface';
import { contactCellExport } from 'components/TableView/addons/cellExports/contactCellExport';
import { RatingScoreCellRenderer } from 'components/cellRenderers/RatingScoreCellRenderer/RatingScoreCellRenderer';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import { TextCellRenderer } from 'components/cellRenderers/TextCellRenderer/TextCellRenderer';
import { TimeCellRenderer } from 'components/cellRenderers/TimeCellRenderer/TimeCellRenderer';
import { BeginSeparatorCellRenderer } from 'components/cellRenderers/BeginSeparatorCellRenderer/BeginSeparatorCellRenderer';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

interface IRatingsColumnsFactoryProps {
  enableClientContacts: boolean;
  t: TFunction;
}

export const columnsOffset = 2;

export const ratingsColumnsFactory = ({
  t,
  enableClientContacts,
}: IRatingsColumnsFactoryProps): IColumnDefinitionType<IRating>[] => {
  return [
    {
      key: 'beginSeparator',
      label: '',
      visibility: true,
      minWidth: '7px',
      width: '7px',
      cellType: 'custom',
      cellRenderer: () => BeginSeparatorCellRenderer(),
    },
    {
      key: 'ratedBy',
      label: t('table.header.labels.ratedBy'),
      visibility: enableClientContacts,
      displayInExport: enableClientContacts,
      minWidth: customWidths.M,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (rating) => (
        <ContactCellRenderer contactData={rating?.reasonObject?.contact} />
      ),
      cellExportHandler: (row) => contactCellExport(row.reasonObject.contact),
    },
    {
      key: 'position',
      label: t('table.header.labels.position'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (rating) =>
        TextCellRenderer({ text: rating.reasonObject?.position }),
      cellExportHandler: (row) => row.reasonObject?.position,
    },
    {
      key: 'date',
      label: t('table.header.labels.date'),
      visibility: true,
      displayInMenu: true,
      isSortable: true,
      cellType: 'custom',
      cellRenderer: (rating) =>
        DateCellRenderer({ date: rating.reasonObject?.start }),
      cellExportHandler: (rating) => new Date(rating.reasonObject?.start),
    },
    {
      key: 'time',
      label: t('table.header.labels.time'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (rating) =>
        TimeCellRenderer({
          start: rating.reasonObject?.start || '',
          end: rating.reasonObject?.end || '',
        }),
      cellExportHandler: ({ reasonObject: { start, end } }) =>
        `
        ${start ? new Date(start) : '-'}
        -
        ${end ? new Date(end) : '-'}`,
    },
    {
      key: 'score',
      label: t('table.header.labels.score'),
      visibility: true,
      width: '100px',
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (rating) => RatingScoreCellRenderer(rating.averageValue),
      cellExportHandler: (rating) => rating.averageValue,
    },
    {
      key: 'note',
      label: t('table.header.labels.note'),
      visibility: true,
      displayInMenu: true,
      cellType: 'string',
      minWidth: customWidths.M,
    },
  ];
};
