import { FC } from 'react';
import { Loader } from 'team-hero-ui';

import { useGetProjectItemQuery } from 'services/teamHeroApi.service';
import { MissionJobsStyled } from './MissionJobsTab.styled';
import { IMissionJobsTabProps } from './MissionJobsTab.types';
import WorkPlanner from 'components/WorkPlanner';

const MissionJobsTab: FC<IMissionJobsTabProps> = ({ mission }) => {
  const { data: project, isLoading } = useGetProjectItemQuery({
    id: mission.project.id,
  });

  if (isLoading) {
    return <Loader loaderType='fixed' />;
  }

  return (
    <MissionJobsStyled>
      {project && <WorkPlanner mission={mission} project={project} />}
    </MissionJobsStyled>
  );
};

export default MissionJobsTab;
