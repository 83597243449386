import { FC } from 'react';
import { IconButton, Icons } from 'team-hero-ui';
import moment from 'moment';

import {
  HoverClickOverlay,
  TimelineBackdropItemInnerStyled,
  TimelineBackdropItemStyled,
} from './TimelineBackdropItem.styled';

interface ITimelineBackdropItemProps {
  size: number;
  isStriped: boolean;
  groupId: string;
  startTime: moment.Moment;
  backdropItemClickCallback?: (value: string, clickTime: moment.Moment) => void;
}

const TimelineBackdropItem: FC<ITimelineBackdropItemProps> = ({
  size,
  isStriped,
  groupId,
  startTime,
  backdropItemClickCallback,
}) => {
  return (
    <TimelineBackdropItemStyled $width={size}>
      <TimelineBackdropItemInnerStyled $isStriped={isStriped}>
        {backdropItemClickCallback && (
          <HoverClickOverlay>
            <IconButton
              onClick={() => backdropItemClickCallback(groupId, startTime)}
            >
              <Icons.PlusIconV2 svgColor='currentColor' svgSize={30} />
            </IconButton>
          </HoverClickOverlay>
        )}
      </TimelineBackdropItemInnerStyled>
    </TimelineBackdropItemStyled>
  );
};

export default TimelineBackdropItem;
