import { FC } from 'react';
import { WorkplannerContactAction } from 'team-hero-ui';

import useGetImageUrl from 'hooks/useGetImageUrl.hook';
import { IAvatarStatusRenderer } from './AvatarStatusRenderer.type';
import { useGetContactItemQuery } from 'services/teamHeroApi.service';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';

const AvatarStatusRenderer: FC<IAvatarStatusRenderer> = ({
  shift,
  forbidAction = false,
}) => {
  const { data: contactData } = useGetContactItemQuery(
    {
      id: getContactIdFromIriOrContact(shift.contact),
    },
    { skip: !getContactIdFromIriOrContact(shift.contact) }
  );
  const { imageUrls } = useGetImageUrl({
    images: contactData?.avatar,
    thumbnailSize: 'small',
  });
  const imageUrl = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  if (shift.contact) {
    return (
      <WorkplannerContactAction
        status={'sameAsWrapper'}
        image={imageUrl}
        size='tiny'
        forbidAction={forbidAction}
        firstName={contactData?.firstName}
        lastName={contactData?.lastName}
        avatarOnClick={() => {}}
        avatarDot={false}
        bgColor={undefined}
      />
    );
  }
  return null;
};

export default AvatarStatusRenderer;
