import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import { TimesheetCategoryCellRenderer } from '../../wta';
import { TimetrackingCategoryStyled } from './TimetrackingCategoryCellRenderer.styled';

export const TimetrackingCategoryCellRenderer = (
  t: TFunction,
  category?: string,
  isBreak?: boolean
): JSX.Element => {
  return (
    <TimetrackingCategoryStyled
      $color={isBreak ? Theme.colors.primary.red : Theme.colors.primary.grey}
    >
      {isBreak ? (
        t('break')
      ) : (
        <TimesheetCategoryCellRenderer category={category} />
      )}
    </TimetrackingCategoryStyled>
  );
};
