import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import type {
  IProjectTimesheet,
  TProjectTimesheetStatus,
} from 'interfaces/ProjectTimesheet.interface';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { getDurationAsHoursByDateString } from 'components/Timeline/helper/timelineItemsCalculate.helper';
import { StatusCellRenderer } from 'components/cellRenderers/StatusCellRenderer/StatusCellRenderer';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import {
  TimetrackingCategoryCellRenderer,
  TimetrackingDurationCellRenderer,
  TimetrackingPositionCellRenderer,
  TimetrackingStatusCellRenderer,
  TimetrackingToDoCellRenderer,
} from 'components/cellRenderers/timetracking';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import { DurationCellRenderer } from 'components/cellRenderers/DurationCellRenderer/DurationCellRenderer';
import { timesheetCellExport } from 'components/TableView/addons/cellExports/timesheetCategoryExport';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

interface IMissionsColumnsFactoryProps {
  displayContactAsDefault: boolean;
  t: TFunction;
}

export const columnsOffset = 2;

export const getStatusColor = (status: TProjectTimesheetStatus): string => {
  switch (status) {
    case 'draft':
      return Theme.colors.primary.red;
    case 'approved':
      return Theme.colors.others.green.green1;
    default:
      return Theme.colors.greyShades.grey4;
  }
};

export const timetrackingsColumnsFactory = ({
  displayContactAsDefault,
  t,
}: IMissionsColumnsFactoryProps): IColumnDefinitionType<IProjectTimesheet>[] => {
  return [
    {
      key: 'status',
      label: '',
      visibility: true,
      width: '7px',
      cellType: 'custom',
      cellRenderer: ({ status }) => StatusCellRenderer(getStatusColor(status)),
    },
    {
      key: 'contact',
      label: t('table.header.labels.contact'),
      visibility: displayContactAsDefault,
      displayInExport: displayContactAsDefault,
      minWidth: customWidths.M,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (timetracking) => (
        <ContactCellRenderer contactData={timetracking.contact} />
      ),
      cellExportHandler: ({ contact }) =>
        `${contact?.firstName} ${contact?.lastName}`,
    },

    {
      key: 'shiftPosition',
      label: t('table.header.labels.position'),
      visibility: true,
      displayInMenu: true,
      minWidth: customWidths.M,
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        TimetrackingPositionCellRenderer(timetracking.shift?.position),
      cellExportHandler: (timetracking) => timetracking.shift?.position || '-',
    },
    {
      key: 'date',
      label: t('table.header.labels.date'),
      visibility: true,
      displayInMenu: true,
      width: '140px',
      isSortable: true,
      sortKey: 'start',
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        DateCellRenderer({ date: timetracking.start, showTime: false }),
    },
    {
      key: 'plannedStart',
      label: t('table.header.labels.plannedStart'),
      visibility: false,
      cellType: 'custom',
      displayInExport: true,
      cellExportHandler: ({ shift }) => {
        return shift ? new Date(shift.start) : '-';
      },
    },
    {
      key: 'plannedEnd',
      label: t('table.header.labels.plannedEnd'),
      visibility: false,
      cellType: 'custom',
      displayInExport: true,
      cellExportHandler: ({ shift }) => {
        return shift ? new Date(shift.end) : '-';
      },
    },
    {
      key: 'time',
      label: t('table.header.labels.time'),
      visibility: true,
      displayInMenu: true,
      width: '180px',
      isSortable: true,
      cellType: 'custom',
      cellRenderer: (timetracking) => (
        <DurationCellRenderer
          start={timetracking.start}
          end={timetracking.end}
        />
      ),
      cellExportHandler: ({ start, end }) => `
        ${start ? new Date(start) : '-'}
        -
        ${end ? new Date(end) : '-'}
      `,
    },
    {
      key: 'duration',
      label: t('table.header.labels.hours'),
      visibility: true,
      displayInMenu: true,
      width: '100px',
      cellType: 'custom',
      cellRenderer: (timetracking) => (
        <TimetrackingDurationCellRenderer
          start={timetracking?.start || ''}
          end={timetracking?.end || ''}
        />
      ),
      cellExportHandler: ({ start, end }) => {
        if (!start || !end) return '-';

        return Number(getDurationAsHoursByDateString(start, end).toFixed(2));
      },
    },

    {
      key: 'timetrackingStatus',
      label: t('table.header.labels.status'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      width: '90px',
      cellRenderer: (timetracking) =>
        TimetrackingStatusCellRenderer(
          timetracking.hasLocation,
          timetracking?.shift?.enableGeolocation
        ),
    },
    {
      key: 'category',
      label: t('table.header.labels.category'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      isSortable: true,
      minWidth: customWidths.M,
      cellRenderer: (timetracking) =>
        TimetrackingCategoryCellRenderer(
          t,
          timetracking.category,
          timetracking.isBreak || false
        ),
      cellExportHandler: (timesheet) => timesheetCellExport(timesheet, t),
    },
    {
      key: 'note',
      label: t('table.header.labels.note'),
      visibility: true,
      displayInMenu: true,
      cellType: 'string',
      minWidth: customWidths.M,
    },
    {
      key: 'toDo',
      label: t('table.header.labels.menu'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        TimetrackingToDoCellRenderer(
          timetracking.isExternallyApproved,
          timetracking.shift?.hasTimetrackingsForExternalApproval,
          timetracking.shift?.hasDraftTimetrackings
        ),
    },
  ];
};
