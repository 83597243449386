import { useCallback } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { TUserRole } from 'interfaces/Role.interface';
import checkAuth from 'routes/checkAuth';

interface IUseCheckAuthReturn {
  check: (roles: TUserRole[]) => boolean;
  checkPasswordChangeRequired: () => boolean;
}

export const useCheckAuth = (): IUseCheckAuthReturn => {
  const user = useCurrentUser();

  // returns boolean true, if one required role is in user roles
  const check = useCallback(
    (requiredRoles: TUserRole[]) => checkAuth({ user, requiredRoles }),
    [user]
  );

  const checkPasswordChangeRequired = useCallback(
    () => user.roles.includes('ROLE_USER_CHANGE_PASSWORD'),
    [user]
  );

  return {
    check,
    checkPasswordChangeRequired,
  };
};
