import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';

const { flexFn } = Utils;

interface ITimetrackingCategoryStyledProps {
  $color?: string;
}

export const TimetrackingCategoryStyled = styled(
  CommonCellContentStyled
)<ITimetrackingCategoryStyledProps>`
  ${flexFn()}
  ${({ $color }) => $color && `color: ${$color}`}
`;
