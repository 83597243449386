import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSystemSettings } from 'hooks/useGetSystemSettings';
import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';

interface ITimesheetCategoryCellRendererProps {
  category?: string;
}

export const TimesheetCategoryCellRenderer: FC<
  ITimesheetCategoryCellRendererProps
> = ({ category }) => {
  const { i18n } = useTranslation(['general']);
  const { timesheetCategories } = useGetSystemSettings();

  if (!category) {
    return <EmptyCell />;
  }

  const timesheetCategoryName =
    timesheetCategories?.[category]?.[i18n.languages[0]];

  return (
    <CommonCellContentStyled>
      {timesheetCategoryName || category}
    </CommonCellContentStyled>
  );
};
