import { FC, useEffect } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import useUserlane from 'hooks/useUserlane.hook';

const UserlaneProvider: FC = () => {
  const { activateUserlane, deactivateUserlane } = useUserlane();
  const user = useCurrentUser();
  useEffect(() => {
    if (import.meta.env.PROD && user.isLoggedIn) {
      activateUserlane();
    } else {
      deactivateUserlane();
    }
  }, [activateUserlane, deactivateUserlane, user.isLoggedIn]);

  return null;
};

export default UserlaneProvider;
