import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import { ITableRow } from 'interfaces/Table/DataTableRow.interface';
import { getGridColsWidth } from 'components/Table/components/util';
import { StyledTableCell } from 'components/Table/components/TableBody/TableBody.styled';

const { pxToRem } = Utils;

export const StyledSummaryHeader = styled.div<ITableRow>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ columns }) => getGridColsWidth(columns)};
  font-size: ${pxToRem(14)};
  border-radius: ${pxToRem(5)};
  cursor: pointer;
  transition: all 0.2s ease-in;
  width: auto;
`;

export const StyledSummaryTableCell = styled(StyledTableCell)`
  background-color: ${Theme.colors.greyShades.grey5};
`;
