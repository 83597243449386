import { Dispatch, SetStateAction } from 'react';
import { Button, Icons, Theme } from 'team-hero-ui';

import LocalizedDatePicker from 'components/LocalizedDatePicker';
import TimelineSizeDropdown from 'components/Timeline/components/TimelineSizeDropdown';
import { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import { RangeTimelineControlsStyled } from './RangeTimelineControls.styled';
import { IRangeViewSettingState } from './RangeTimelineControls.types';
import useTimelineRangeControls from 'hooks/useTimelineRangeControls.hook';

interface IRangeTimelineControlsProps<T> {
  timelineSettings: IRangeViewSettingState<T>;
  setTimelineSettings: Dispatch<SetStateAction<IRangeViewSettingState<T>>>;
}

const RangeTimelineControls = <T,>({
  timelineSettings,
  setTimelineSettings,
}: IRangeTimelineControlsProps<T>): JSX.Element => {
  const timelineSizeItems: TTimelineSize[] = ['1week', '2weeks', '1month'];

  const {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleDateChange,
    handleOnClickToday,
    handleSetTimelineSize,
  } = useTimelineRangeControls({ setTimelineSettings });

  return (
    <RangeTimelineControlsStyled data-test-id='range-timeline-controls'>
      <Button onClick={handleOnClickToday} data-test-id='today-button'>
        <Icons.TargetIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={21}
        />
      </Button>
      <TimelineSizeDropdown
        value={timelineSettings.timelineSize}
        handleChange={handleSetTimelineSize}
        timelineSizeItems={timelineSizeItems}
      />
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.startDate.toDate()}
        onChange={(value) => handleDateChange(value, 'start')}
        clickHandlerLeftArrow={() => handleOnClickLeftArrow('start')}
        clickHandlerRightArrow={() => handleOnClickRightArrow('start')}
      />
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.endDate.toDate()}
        onChange={(value) => handleDateChange(value, 'end')}
        clickHandlerLeftArrow={() => handleOnClickLeftArrow('end')}
        clickHandlerRightArrow={() => handleOnClickRightArrow('end')}
      />
    </RangeTimelineControlsStyled>
  );
};

export default RangeTimelineControls;
