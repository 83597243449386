import { useMemo } from 'react';
import { createEmptyEditorState, exportToHtml } from 'team-hero-ui';

import type { IMessage } from 'interfaces/Message.interface';
import type { ICreateMessageState } from './MessageInput.types';
import type { IPostMessageBody } from 'services/messages/messages.api.types';
import type { IMessageConversation } from 'interfaces/MessageConversation.interface';
import type { IPostConversationBody } from 'services/messageConversations/messageConversations.api.types';
import type { IMessageFileResource } from 'interfaces/File.interface';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import {
  teamHeroApi,
  usePostMessageConversationMutation,
  usePostMessageFileEntityItemMutation,
  usePostMessageFileItemMutation,
  usePostMessageMutation,
  usePutMessageMutation,
} from 'services/teamHeroApi.service';
import { useAppDispatch } from 'store/hooks';

interface ICreateMessageArgs {
  state: ICreateMessageState;
  fromContactIri: string;
  isNewConversation?: boolean;
  isFromDesktop: boolean;
  conversationIri?: string;
  to?: string[];
}
interface ICreateMessageConversationArgs extends ICreateMessageArgs {
  related?: string;
  assignee?: string;
}

interface IUseCreateMessageFormStrategiesReturn {
  initialCreateMessageState: ICreateMessageState;
  createMessageStrategy: (args: ICreateMessageArgs) => Promise<IMessage | void>;
  createMessageConversationStrategy: (
    args: ICreateMessageConversationArgs
  ) => Promise<IMessageConversation | void>;
  isSubmittingMessage: boolean;
}

export const useCreateMessageFormStrategies =
  (): IUseCreateMessageFormStrategiesReturn => {
    const dispatch = useAppDispatch();

    const { handleError } = useErrorHandler();
    const [postConversation, { isLoading: isSubmittingConversation }] =
      usePostMessageConversationMutation();

    const [postMessage, { isLoading: isSubmittingMessage }] =
      usePostMessageMutation();

    const [putMessage, { isLoading: isSubmittingPutMessage }] =
      usePutMessageMutation();

    const [postMessageFile, { isLoading: isSubmittingMessageFile }] =
      usePostMessageFileEntityItemMutation();

    const [postMessageFileItem, { isLoading: isSubmittingMessageFileItem }] =
      usePostMessageFileItemMutation();

    const createMessageFiles = (
      files: File[],
      messageIri: string
    ): Promise<IMessageFileResource[]> => {
      return Promise.all(
        files.map((file) => {
          const postFileEndpoint = postMessageFile({
            message: messageIri,
          });

          const postFilePromise = postFileEndpoint.unwrap().then((result) => {
            const body = new FormData();
            body.append('file', file);

            const postMessageFileItemEndpoint = postMessageFileItem({
              id: result.id,
              body,
            });

            return postMessageFileItemEndpoint.unwrap();
          });

          postFileEndpoint.reset();

          return postFilePromise;
        })
      );
    };

    const initialCreateMessageState: ICreateMessageState = useMemo(() => {
      return {
        content: createEmptyEditorState(),
        mobileInput: '',
        subject: '',
        type: 'teamhero',
        attachments: [],
      };
    }, []);

    const mapMessageConversationStateToConversationBody = ({
      state,
      assignee,
      related,
    }: Partial<ICreateMessageConversationArgs>): IPostConversationBody => {
      return {
        assignee,
        isDone: false,
        isGroupChat: false,
        related: related || null,
        title: !!state?.subject ? state.subject : undefined,
      };
    };

    const mapMessageStateToMessageBody = ({
      state,
      fromContactIri,
      isFromDesktop,
      isNewConversation,
      conversationIri,
      to,
    }: ICreateMessageArgs): IPostMessageBody => {
      const contentValue = state.content && exportToHtml(state.content);

      return {
        content: isFromDesktop ? contentValue : state.mobileInput,
        type: 'teamhero',
        subject: state.subject ? state.subject : null,
        plannedSendAt: null,
        conversation: isNewConversation ? undefined : conversationIri,
        fromContact: fromContactIri,
        to,
      };
    };

    const createMessageStrategy = ({
      state,
      fromContactIri,
      isNewConversation,
      isFromDesktop,
      conversationIri,
      to,
    }: ICreateMessageArgs): Promise<IMessage | void> => {
      const postMessageBody = mapMessageStateToMessageBody({
        state,
        fromContactIri,
        isNewConversation,
        isFromDesktop,
        conversationIri,
        to,
      });

      return postMessage({
        body: {
          ...postMessageBody,
          status: state.attachments.length > 0 ? 'draft' : 'submitted',
        },
      })
        .unwrap()
        .then((messageResult: IMessage) => {
          if (state.attachments.length > 0) {
            return Promise.all([
              createMessageFiles(state.attachments, messageResult['@id']),
            ]).then(() => {
              return putMessage({
                id: messageResult.id,
                body: {
                  ...postMessageBody,
                  status: 'submitted',
                },
              }).unwrap();
            });
          } else {
            return messageResult;
          }
        })
        .then((message) => Promise.resolve(message))
        .catch((error) => handleError(error));
    };

    const createMessageConversationStrategy = (
      conversationArgs: ICreateMessageConversationArgs
    ): Promise<IMessageConversation | void> => {
      const conversationPostBody: IPostConversationBody =
        mapMessageConversationStateToConversationBody(conversationArgs);

      return postConversation({
        body: conversationPostBody,
        skipInvalidateTags: true,
      })
        .unwrap()
        .then(async (conversationResult: IMessageConversation) => {
          await createMessageStrategy({
            isFromDesktop: conversationArgs.isFromDesktop,
            isNewConversation: false,
            conversationIri: conversationResult['@id'],
            fromContactIri: conversationArgs.fromContactIri,
            state: conversationArgs.state,
            to: conversationArgs.to,
          });
          return conversationResult;
        })
        .then((conversation) => Promise.resolve(conversation))
        .catch((error) => handleError(error))
        .finally(() => {
          dispatch(teamHeroApi.util.invalidateTags(['MessageConversation']));
        });
    };

    return {
      initialCreateMessageState,
      createMessageConversationStrategy,
      createMessageStrategy,
      isSubmittingMessage:
        isSubmittingConversation ||
        isSubmittingMessage ||
        isSubmittingPutMessage ||
        isSubmittingMessageFile ||
        isSubmittingMessageFileItem,
    };
  };
