import React from 'react';
import moment from 'moment';

import {
  calculateIncrementResolution,
  calculateTimeLineStart,
} from 'components/Timeline/helper/timelineSettings.helper';
import {
  ITimelineSettingsState,
  TTimelineSize,
} from 'components/Timeline/interfaces/timelineResolutions.interface';

interface IUseTimelineControlsReturn {
  handleSetTimelineSize: (newTimelineSizeValue: TTimelineSize) => void;
  handleOnClickLeftArrow: () => void;
  handleOnClickRightArrow: () => void;
  handleOnClickToday: () => void;
  handleStartDateChange: (date: Date) => void;
}

interface IUseTimelineControlsProps<T> {
  setTimelineSettings: React.Dispatch<React.SetStateAction<T>>;
}

const useTimelineControls = <T extends ITimelineSettingsState>({
  setTimelineSettings,
}: IUseTimelineControlsProps<T>): IUseTimelineControlsReturn => {
  const handleStartDateChange = (date: Date): void => {
    setTimelineSettings((prevState) => {
      const updatedStartDate = calculateTimeLineStart(
        prevState.timelineSize,
        moment(date)
      );
      return {
        ...prevState,
        startDate: updatedStartDate,
      };
    });
  };

  const handleSetTimelineSize = (newTimelineSizeValue: TTimelineSize): void => {
    setTimelineSettings((prevState) => {
      const updatedStartDate = calculateTimeLineStart(
        newTimelineSizeValue,
        prevState.startDate
      );
      return {
        ...prevState,
        startDate: updatedStartDate,
        timelineSize: newTimelineSizeValue,
      };
    });
  };

  const handleOnClickLeftArrow = (): void => {
    setTimelineSettings((prevState) => {
      const updatedStartDate = prevState.startDate.subtract(
        1,
        calculateIncrementResolution(prevState.timelineSize)
      );
      return {
        ...prevState,
        startDate: updatedStartDate,
      };
    });
  };

  const handleOnClickRightArrow = (): void => {
    setTimelineSettings((prevState) => {
      const updatedStartDate = prevState.startDate.add(
        1,
        calculateIncrementResolution(prevState.timelineSize)
      );
      return {
        ...prevState,
        startDate: updatedStartDate,
      };
    });
  };

  const handleOnClickToday = (): void => {
    setTimelineSettings((prevState) => {
      const updatedStartDate = calculateTimeLineStart(
        prevState.timelineSize,
        moment()
      );
      return {
        ...prevState,
        startDate: updatedStartDate,
      };
    });
  };

  return {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleSetTimelineSize,
    handleOnClickToday,
    handleStartDateChange,
  };
};

export default useTimelineControls;
