import type { IApplicationEmbed } from 'interfaces/Applications.interface';
import type {
  IContactEmbed,
  ISystemContact,
} from 'interfaces/Contact.interface';
import type { IContactAbsence } from 'interfaces/ContactAbsence.interface';
import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { INote } from 'interfaces/Note.interface';
import type {
  IProjectEmbed,
  IProjectMiniEmbed,
} from 'interfaces/Project.interface';
import type {
  IProjectMissionEmbed,
  IProjectMissionMiniEmbed,
} from 'interfaces/ProjectMission.interface';
import type { IProjectShiftEmbed } from 'interfaces/ProjectShift.interface';
import type { ICompanyEmbed } from 'interfaces/Company.interface';
import type { IMessageFileEmbed } from 'interfaces/Message.interface';
import { IMessageConversation } from 'interfaces/MessageConversation.interface';

export const checkEmbedIsString = (embed: unknown): embed is string =>
  typeof embed === 'string';

export const checkEmbedIsSystemContact = (
  embed: unknown
): embed is ISystemContact =>
  typeof embed === 'object' &&
  embed !== null &&
  'type' in embed &&
  (embed as ISystemContact).type === 'system';

export const checkEmbedIsMission = (
  embed: unknown
): embed is IProjectMissionEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ProjectMission' &&
  // does contain id and title
  'id' in embed &&
  'title' in embed;

export const checkEmbedIsMissionMini = (
  embed: unknown
): embed is IProjectMissionMiniEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ProjectMission' &&
  // does not contain id and title
  !('id' in embed) &&
  !('title' in embed);

export const checkEmbedIsProject = (embed: unknown): embed is IProjectEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Project' &&
  // does contain id and title
  'id' in embed &&
  'title' in embed;

export const checkEmbedIsProjectMini = (
  embed: unknown
): embed is IProjectMiniEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Project' &&
  // does not contain id and title
  !('id' in embed) &&
  !('title' in embed);

export const checkEmbedIsAbsence = (embed: unknown): embed is IContactAbsence =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ContactOutOfOffice';

export const checkEmbedIsContact = (embed: unknown): embed is IContactEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Contact';

export const checkEmbedIsApplication = (
  embed: unknown
): embed is IApplicationEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Application';

export const checkEmbedIsContactDocument = (
  embed: unknown
): embed is IContactDocument =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ContactDocument';

export const checkEmbedIsApplicationDocument = (
  embed: unknown
): embed is IContactDocument =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ApplicationDocument';

export const checkEmbedIsNote = (embed: unknown): embed is INote =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Note';

export const checkEmbedIsShift = (
  embed: unknown
): embed is IProjectShiftEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'ProjectShift';

export const checkEmbedIsCompany = (embed: unknown): embed is ICompanyEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'Company';

export const checkEmbedIsMessageFile = (
  embed: unknown
): embed is IMessageFileEmbed =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  (embed as never)['@type'] === 'MessageFile';

export const checkEmbedIsMessageConversation = (
  embed: unknown
): embed is IMessageConversation =>
  typeof embed === 'object' &&
  embed !== null &&
  '@type' in embed &&
  '@id' in embed &&
  (embed as never)['@type'] === 'MessageConversation';
