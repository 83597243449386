import {
  TWorkplannerShift,
  TProjectShiftStatus,
} from 'interfaces/ProjectShift.interface';

interface IShiftStatusCountHelperResponse {
  openCount: number;
  plannedCount: number;
  bookedCount: number;
  confirmedCount: number;
  deactivatedCount: number;
}

export const getOpenCount = (items: TWorkplannerShift[]): number =>
  items.reduce((accumulator, item) => {
    return item.status === 'open' ? accumulator + 1 : accumulator;
  }, 0);

const getPlannedCount = (items: TWorkplannerShift[]): number =>
  items.reduce((accumulator, item) => {
    return item.status === 'planned' ? accumulator + 1 : accumulator;
  }, 0);

const getBookedCount = (items: TWorkplannerShift[]): number =>
  items.reduce((accumulator, item) => {
    return item.status === 'booked' ? accumulator + 1 : accumulator;
  }, 0);

const getConfirmedCount = (items: TWorkplannerShift[]): number =>
  items.reduce((accumulator, item) => {
    return item.status === 'confirmed' ? accumulator + 1 : accumulator;
  }, 0);

const getDeactivatedCount = (items: TWorkplannerShift[]): number =>
  items.reduce((accumulator, item) => {
    return item.status === 'deactivated' ? accumulator + 1 : accumulator;
  }, 0);

export const shiftStatusCountHelper = (
  items: TWorkplannerShift[]
): IShiftStatusCountHelperResponse => {
  return {
    openCount: getOpenCount(items),
    plannedCount: getPlannedCount(items),
    bookedCount: getBookedCount(items),
    confirmedCount: getConfirmedCount(items),
    deactivatedCount: getDeactivatedCount(items),
  };
};

export const getCombinedShiftStatus = (
  shifts: TWorkplannerShift[]
): TProjectShiftStatus => {
  const { openCount, plannedCount, bookedCount, confirmedCount } =
    shiftStatusCountHelper(shifts);

  if (openCount > 0) {
    return 'open';
  }
  if (plannedCount > 0) {
    return 'planned';
  }
  if (bookedCount > 0) {
    return 'booked';
  }
  if (confirmedCount > 0) {
    return 'confirmed';
  }
  return 'deactivated';
};
