import { useCallback, useEffect } from 'react';

import { TUserRole } from 'interfaces/Role.interface';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useScript from 'hooks/useScript.hook';
import { useToggle } from 'hooks/useToggle.hook';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Userlane: CallableFunction;
  }
}

interface IUseUserlaneReturn {
  activateUserlane: () => void;
  deactivateUserlane: () => void;
}

type TUserlaneRole =
  | 'ROLE_ADMIN'
  | 'ROLE_HR_MANAGER'
  | 'ROLE_HERO_LOGIN'
  | 'ROLE_CLIENT'
  | 'ROLE_COCKPIT_LOGIN'
  | 'ROLE_UNKNOWN';

const getUserlaneRoleByUser = (userRoles: TUserRole[]): TUserlaneRole => {
  if (userRoles.includes('ROLE_ADMIN')) {
    return 'ROLE_ADMIN';
  }

  if (userRoles.includes('ROLE_HR_MANAGER')) {
    return 'ROLE_HR_MANAGER';
  }

  if (userRoles.includes('ROLE_COCKPIT_LOGIN')) {
    return 'ROLE_COCKPIT_LOGIN';
  }

  if (userRoles.includes('ROLE_CLIENT')) {
    return 'ROLE_CLIENT';
  }

  if (userRoles.includes('ROLE_HERO_LOGIN')) {
    return 'ROLE_HERO_LOGIN';
  }
  return 'ROLE_UNKNOWN';
};

const useUserlane = (): IUseUserlaneReturn => {
  const {
    toggleValue: scriptEnabled,
    on: setScriptEnabled,
    off: setScriptDisabled,
  } = useToggle(false);
  const user = useCurrentUser();
  const { currentUser } = useCurrentUserContact(
    !scriptEnabled || !user.isLoggedIn
  );

  const userlaneProjectId = import.meta.env.VITE_USERLANE_PROJECT_ID;

  const userlaneScriptStatus = useScript(
    `https://cdn.userlane.com/userlane.js`,
    scriptEnabled && !!userlaneProjectId
  );

  const deactivateUserlane = useCallback(() => {
    setScriptDisabled();
    if (window.Userlane) {
      window.Userlane('hide');
    }
  }, [setScriptDisabled]);

  const initialize = useCallback(() => {
    if (!!userlaneProjectId && user.isLoggedIn && currentUser) {
      window.Userlane('init', userlaneProjectId);
      window.Userlane(
        'identify',
        `${window.location.host}_${currentUser?.id}`,
        {
          user_role: getUserlaneRoleByUser(user.roles),
          environment: 'HEROLOGIN',
        }
      );
      window.Userlane('lang', currentUser.locale);
    }
  }, [currentUser, user.isLoggedIn, user.roles, userlaneProjectId]);

  useEffect(() => {
    if (userlaneScriptStatus === 'ready' && user.isLoggedIn) {
      initialize();
    }
  }, [initialize, user.isLoggedIn, userlaneScriptStatus]);

  const activateUserlane = useCallback(() => {
    if (userlaneProjectId) {
      setScriptEnabled();
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'No userlane project id defined. Please check your environment and set it'
      );
    }
  }, [setScriptEnabled, userlaneProjectId]);

  return {
    activateUserlane,
    deactivateUserlane,
  };
};

export default useUserlane;
