import { IconButton, Icons, theme } from 'team-hero-ui';

import { IUseSortReturn } from 'components/TableView/hooks/useSort.hook';
import { TSortDirection } from 'interfaces/Pagination.interface';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import {
  ButtonWrapper,
  StyledTableHeaderCell,
  StyledTableHeaderRow,
  StyledTableHeaderSortWrapper,
} from './TableHeader.styled';

interface ITableHeaderProps<T> {
  columns: Array<IColumnDefinitionType<T>>;
  defaultCellWidth?: string;
  sorting: IUseSortReturn;
}

export function TableHeader<T>({
  columns,
  defaultCellWidth = '50px',
  sorting,
}: ITableHeaderProps<T>): JSX.Element {
  const renderCell = (column: IColumnDefinitionType<T>) => {
    const onUpClick = () => {
      sorting.onSetSorting(column.sortKey ? column.sortKey : column.key, 'asc');
    };
    const onDownClick = () => {
      sorting.onSetSorting(
        column.sortKey ? column.sortKey : column.key,
        'desc'
      );
    };
    const checkIfIconIsSelected = (type: TSortDirection) => {
      const keyToCompare = column.sortKey || column.key;
      return keyToCompare === sorting.columnKey && type === sorting.direction;
    };

    return (
      <StyledTableHeaderCell
        key={`headCell-${column.key}`}
        $minWidth={column.minWidth || defaultCellWidth}
        $width={column.width}
        $sortDirection={sorting.direction}
        $isSorted={
          column.key === sorting.columnKey ||
          column.sortKey === sorting.columnKey
        }
      >
        {column.label}
        {column.isSortable && (
          <StyledTableHeaderSortWrapper>
            <ButtonWrapper $keepVisible={checkIfIconIsSelected('asc')}>
              <IconButton size='small' onClick={onUpClick}>
                <Icons.CaretUpIcon
                  svgSize={14}
                  svgColor={
                    checkIfIconIsSelected('asc')
                      ? theme.colors.greyShades.grey2
                      : theme.colors.greyShades.grey3
                  }
                />
              </IconButton>
            </ButtonWrapper>
            <ButtonWrapper $keepVisible={checkIfIconIsSelected('desc')}>
              <IconButton size='small' onClick={onDownClick}>
                <Icons.CaretDownIcon
                  svgSize={14}
                  svgColor={
                    checkIfIconIsSelected('desc')
                      ? theme.colors.greyShades.grey2
                      : theme.colors.greyShades.grey3
                  }
                />
              </IconButton>
            </ButtonWrapper>
          </StyledTableHeaderSortWrapper>
        )}
      </StyledTableHeaderCell>
    );
  };

  const headers = columns.map((column) => {
    return column.visibility ? renderCell(column) : null;
  });

  return (
    <StyledTableHeaderRow data-component='table-header-row' columns={columns}>
      {headers}
    </StyledTableHeaderRow>
  );
}
