import { Button, Card, Icons, Theme } from 'team-hero-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getFullAddress } from 'helpers/address/address.helper';
import { IMissionOverviewCardProps } from './MissionOverviewCard.types';
import { useOpenMaps } from 'hooks/useOpenMaps.hook';
import {
  AddressCityStyled,
  CardContentStyled,
  JobsHeaderStyled,
  MissionOverviewCardStyled,
  ShiftCountStyled,
  ShiftsCountStyled,
} from './MissionOverviewCard.styled';
import {
  DefaultText,
  GreyThinText,
  ProjectMissionInfoSection,
} from 'pages/Work/ProjectMissionDetails/ProjectMissionInfo/ProjectMissionInfo.styled';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { useGetWidgetsQuery } from 'services/teamHeroApi.service';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import {
  IMissionJobsWidget,
  IWidget,
} from 'interfaces/Widget/Widget.interface';

const MissionOverviewCard: FC<IMissionOverviewCardProps> = ({ mission }) => {
  const { t } = useTranslation('general');

  const widgetFilters: ISimpleFilter[] = [
    {
      key: 'group',
      operator: 'AND',
      value: 'mission-overview',
    },
    {
      key: 'mission_id',
      operator: 'AND',
      value: mission.id,
    },
  ];

  const { data: widgetsData } = useGetWidgetsQuery({ filters: widgetFilters });

  const widgetItems = (widgetsData?.items ||
    []) as IWidget<IMissionJobsWidget>[];

  const shifts = widgetItems?.find((item) => item.key === 'mission-jobs')
    ?.data as IMissionJobsWidget | undefined;

  const { openMapSearch } = useOpenMaps();

  const { formatDate } = useDateFormat();

  const handleOpenMap = (): void => {
    openMapSearch(`${getFullAddress(mission)}`);
  };

  return (
    <MissionOverviewCardStyled>
      <Card
        title={
          mission.addressCity ? (
            <Button
              type='button'
              endIcon={
                <Icons.MapIcon
                  svgSize={12}
                  svgColor={Theme.colors.primary.grey}
                />
              }
              onClick={handleOpenMap}
            >
              <AddressCityStyled>{mission.addressCity}</AddressCityStyled>
            </Button>
          ) : null
        }
      >
        <CardContentStyled>
          <ProjectMissionInfoSection
            $justifyContent='space-between'
            $alignItems='center'
            $gap={24}
            $margin={[mission.addressCity ? 0 : -24, 0, 24, 0]}
          >
            <ProjectMissionInfoSection
              $width='unset'
              $justifyContent='flex-start'
              $gap={24}
              $margin={0}
            >
              <div>
                <GreyThinText>
                  {formatDate(mission.start, 'dayFull')}
                </GreyThinText>
                <br />
                <DefaultText>{formatDate(mission.start, 'date')}</DefaultText>
              </div>
              <span>
                <Icons.ArrowLongIcon
                  svgColor={Theme.colors.primary.grey}
                  svgSize={24}
                />
              </span>
              <div>
                <GreyThinText>
                  {formatDate(mission.end, 'dayFull')}
                </GreyThinText>
                <br />
                <DefaultText>{formatDate(mission.end, 'date')}</DefaultText>
              </div>
            </ProjectMissionInfoSection>
          </ProjectMissionInfoSection>
          <JobsHeaderStyled>
            <Icons.ShiftIcon
              svgColor={Theme.colors.primary.grey}
              svgSize={30}
            />
            <p>
              <span>{shifts?.total}</span> {t('details.jobs')}
            </p>
          </JobsHeaderStyled>

          <ShiftsCountStyled>
            <div>
              {Object.values(shifts?.position || {}).map((value) => (
                <ShiftCountStyled>
                  <span>{value.total}</span>
                </ShiftCountStyled>
              ))}
            </div>
            <div>
              {Object.keys(shifts?.position || {}).map((shiftPosition) => (
                <ShiftCountStyled>
                  <p>{shiftPosition}</p>
                </ShiftCountStyled>
              ))}
            </div>
          </ShiftsCountStyled>
        </CardContentStyled>
      </Card>
    </MissionOverviewCardStyled>
  );
};

export default MissionOverviewCard;
