import { styled } from 'styled-components';
import { Button, Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const MissionOverviewCardStyled = styled.div``;

export const AddressCityStyled = styled.p`
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
  font-weight: 700;
`;

export const CardContentStyled = styled.div`
padding ${pxToRem([0, 12])};
`;

export const EmptyTitleStyled = styled.div`
  margin-top: ${pxToRem(-24)};
`;

export const JobsHeaderStyled = styled.div`
  color: ${Theme.colors.primary.grey};
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  margin-bottom: ${pxToRem(12)};
  span {
    font-size: ${pxToRem(24)};
  }
  p {
    font-size: ${pxToRem(15)};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const ShiftsCountStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  gap: ${pxToRem(8)};
`;

export const ShiftCountStyled = styled.div`
  p,
  span {
    font-size: ${pxToRem(14)};
  }

  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  color: ${Theme.colors.greyShades.grey2};
  span {
    text-align: right;
    font-weight: 700;
    width: 100%;
  }
`;

export const LiveModeButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    text-transform: uppercase;
    box-shadow: 0 ${pxToRem(-3)} ${pxToRem(3)} 0
      ${Theme.colors.greyShades.grey1}20;
  }
`;
