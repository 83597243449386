import type { IProjectMission } from 'interfaces/ProjectMission.interface';

export const getFullAddress = (mission?: IProjectMission): string => {
  if (!mission) {
    return '';
  }
  let fullAddressString = '';

  if (mission.addressStreet) {
    fullAddressString += mission.addressStreet;
  }

  if (mission.addressStreet && (mission.addressCity || mission.addressZip)) {
    fullAddressString += ', ';
  }

  if (mission.addressZip) {
    fullAddressString += mission.addressZip + ' ';
  }

  if (mission.addressCity) {
    fullAddressString += mission.addressCity;
  }

  return fullAddressString;
};

export const hasAddressData = (mission: IProjectMission): boolean => {
  return (
    !!mission.addressStreet || !!mission.addressCity || !!mission.addressZip
  );
};
