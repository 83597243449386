import { styled, css } from 'styled-components';
import { Button, Utils } from 'team-hero-ui';

import { TBulkEditRowPosition } from './BulkEditRow.type';

const { flexFn, pxToRem } = Utils;

interface IBulkEditRowStyledProps {
  $position: TBulkEditRowPosition;
}

export const bulkEditIconSize = 20;

const topCSS = css`
  top: 0;
  margin-top: ${pxToRem(5)};
`;

const bottomCSS = css`
  bottom: 0;
  margin-bottom: ${pxToRem(5)};
`;

const commonCss = css`
  left: ${pxToRem(-15)};
  background-color: ${({ theme }) => theme.colors.greyShades.grey2};
  position: absolute;
  content: '';
`;

const angleSize = pxToRem(2);

export const BulkEditRowStyled = styled.div<IBulkEditRowStyledProps>`
  ${flexFn('flex-start')}
  gap: ${pxToRem(20)};
  margin-left: ${pxToRem(60)};
  padding-left: ${pxToRem(60)};
  height: ${pxToRem(40)};
  position: relative;

  &::before {
    ${({ $position }) => ($position === 'top' ? bottomCSS : topCSS)}
    height: calc(50% - 5px);
    width: ${angleSize};
    ${commonCss}
  }
  &::after {
    height: ${angleSize};
    width: ${pxToRem(60)};
    ${commonCss}
  }
`;

export const ActionButtonStyled = styled(Button)`
  &.MuiButton-root {
    color: ${({ theme }) => theme.colors.greyShades.grey2};

    &:hover {
      color: ${({ theme }) => theme.colors.greyShades.grey3};
    }
  }
`;
