import { useCallback } from 'react';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TRootState } from 'store';
import { ISelector } from 'interfaces/Selector.interface';

export type TCompareOperator = 'AND' | 'OR' | 'NOT';
export type TFilterValue = string | number | boolean | null;

export interface ISimpleFilter {
  key: string;
  operator: TCompareOperator;
  value: string | number | boolean | null;
}

export interface IUseFiltersReturn {
  onSetFilters: (filters: ISimpleFilter[], callback?: () => void) => void;
  onClearFilters: () => void;
  filters: ISimpleFilter[];
  nestedFilters: { [key: string]: ISimpleFilter[] };
  activeFilterCount: number;
  initialFiltersSet: boolean;
}

type TDataCollectionFiltersReduxSlice =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ActionCreatorWithPayload<any, `${string}/${string}`>
  | ActionCreatorWithoutPayload<`${string}/${string}`>;

export const useFilters = (
  setFiltersDispatch: TDataCollectionFiltersReduxSlice,
  paginationSelector: (state: TRootState) => ISelector,
  onSetFilterCallback?: () => void
): IUseFiltersReturn => {
  const dispatch = useAppDispatch();

  const onSetFilters = useCallback(
    (payloadFilters: ISimpleFilter[], callback?: () => void) => {
      dispatch(setFiltersDispatch(payloadFilters));
      if (onSetFilterCallback) {
        onSetFilterCallback();
      }
      if (callback) {
        callback();
      }
    },
    [dispatch, onSetFilterCallback, setFiltersDispatch]
  );

  const onClearFilters = useCallback(() => {
    dispatch(setFiltersDispatch([]));
    if (onSetFilterCallback) {
      onSetFilterCallback();
    }
  }, [dispatch, setFiltersDispatch, onSetFilterCallback]);

  const { filters, initialFiltersSet, nestedFilters } =
    useAppSelector(paginationSelector);

  const activeFilterCount = filters.length;

  return {
    onSetFilters,
    onClearFilters,
    filters,
    nestedFilters,
    activeFilterCount,
    initialFiltersSet,
  };
};
