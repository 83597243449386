import { TFunction } from 'i18next';

import i18n from 'i18n/config';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { store } from 'store';
import { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';

export const timesheetCellExport = async (
  timesheet: IProjectTimesheet,
  t: TFunction
): Promise<string> => {
  if (timesheet.isBreak) {
    return t('break');
  }

  if (timesheet.category) {
    const endpoint = store.dispatch(
      teamHeroApi.endpoints.getSystemSettings.initiate()
    );

    endpoint.unsubscribe();
    const settingsData = await endpoint.unwrap();

    const timesheetCategories = settingsData?.settings.timesheet_categories;

    return (
      timesheetCategories?.[timesheet.category]?.[i18n.languages[0]] ||
      timesheet.category
    );
  } else {
    return '-';
  }
};
