import { ReactElement } from 'react';

import TimelineBody from './components/TimelineBody';
import { ITimelineProps } from './Timeline.type';
import { TTimelineItemVariant } from './interfaces/timeline.interface';

const Timeline = <T extends TTimelineItemVariant>({
  timelineWidth,
  timelineStart,
  timelineEnd,
  ...props
}: ITimelineProps<T>): ReactElement => {
  return (
    <TimelineBody
      width={timelineWidth}
      start={timelineStart}
      end={timelineEnd}
      {...props}
    />
  );
};

export default Timeline;
