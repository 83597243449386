import { styled } from 'styled-components';
import { Button, Utils } from 'team-hero-ui';

import { ButtonWithoutStyle } from 'helpers/styles/styles.helper';

const { pxToRem } = Utils;

export const DownloadButtonStyled = styled(Button)`
  ${ButtonWithoutStyle}
  gap: ${pxToRem(4)};
`;
