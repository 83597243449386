import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Tabs } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { ClientLoginMissionDetailsStyled } from './ClientLoginMissionDetails.styled';
import MissionDetailsHeader from 'components/ClientLoginMissionDetails/MissionDetailsHeader';
import { useSearchUrlParams } from 'hooks/useSearchUrlParams.hook';
import { TMissionDetailsView } from './ClientLoginMissionDetails.types';
import MissionJobsTab from 'components/ClientLoginMissionDetails/Tabs/MissionJobsTab';
import MissionTimetrackingsTab from 'components/ClientLoginMissionDetails/Tabs/MissionTimetrackingsTab';
import MissionOverviewTab from 'components/ClientLoginMissionDetails/Tabs/MissionOverviewTab';
import MissionRatingsTab from 'components/ClientLoginMissionDetails/Tabs/MissionRatingsTab';
import MissionApplicationsTab from 'components/ClientLoginMissionDetails/Tabs/MissionApplicationsTab';
import { useGetProjectMissionItemQuery } from 'services/teamHeroApi.service';

type TUrlParams = {
  id: string;
};

const ClientLoginMissionDetails: FC = () => {
  const { t } = useTranslation('general');

  const { id } = useParams<TUrlParams>();
  const selectedId = parseInt(id || '0', 10);

  const {
    isError,
    isLoading,
    data: mission,
  } = useGetProjectMissionItemQuery(
    {
      id: selectedId,
    },
    { skip: !id || selectedId === 0 }
  );

  const {
    selectedSearchParam: selectedTabValue,
    onSetSearchParam: onSelectTab,
  } = useSearchUrlParams<TMissionDetailsView>({ defaultValue: 'overview' });

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!mission) {
    return <div>{t('common.itemNotFound')}</div>;
  }

  return (
    <ClientLoginMissionDetailsStyled>
      <MissionDetailsHeader mission={mission}>
        <Tabs.Tabs<TMissionDetailsView>
          value={selectedTabValue}
          onChange={onSelectTab}
          isSticky
        >
          <Tabs.Tab<TMissionDetailsView>
            value='overview'
            label={t('details.overview')}
            dataTestId={'contact-details-overview-tab'}
          />
          <Tabs.Tab<TMissionDetailsView>
            value='jobs'
            label={t('details.jobs')}
            dataTestId={'contact-details-jobs-tab'}
          />
          <Tabs.Tab<TMissionDetailsView>
            value='timetrackings'
            label={t('details.timetrackings')}
            dataTestId={'contact-details-timetrackings-tab'}
          />
          <Tabs.Tab<TMissionDetailsView>
            value='ratings'
            label={t('details.ratings')}
            dataTestId={'contact-details-ratings-tab'}
          />
          <Tabs.Tab<TMissionDetailsView>
            value='applications'
            label={t('details.applications')}
            dataTestId={'contact-details-applications-tab'}
          />
        </Tabs.Tabs>
        <Tabs.TabPanel<TMissionDetailsView>
          value={selectedTabValue}
          panelValue='overview'
          index={0}
        >
          <MissionOverviewTab mission={mission} />
        </Tabs.TabPanel>
        <Tabs.TabPanel<TMissionDetailsView>
          value={selectedTabValue}
          panelValue='jobs'
          index={1}
        >
          <MissionJobsTab mission={mission} />
        </Tabs.TabPanel>
        <Tabs.TabPanel<TMissionDetailsView>
          value={selectedTabValue}
          panelValue='timetrackings'
          index={2}
        >
          <MissionTimetrackingsTab
            filterPropertyName='shift.mission'
            id={mission.id}
            projectId={mission.project.id}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel<TMissionDetailsView>
          value={selectedTabValue}
          panelValue='ratings'
          index={3}
        >
          <MissionRatingsTab missionId={mission.id} />
        </Tabs.TabPanel>
        <Tabs.TabPanel<TMissionDetailsView>
          value={selectedTabValue}
          panelValue='applications'
          index={4}
        >
          <MissionApplicationsTab missionId={mission.id} />
        </Tabs.TabPanel>
      </MissionDetailsHeader>
    </ClientLoginMissionDetailsStyled>
  );
};

export default ClientLoginMissionDetails;
