import { FC, useEffect } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import useUserSnap from 'hooks/useUserSnap.hook';

const UserSnapProvider: FC = () => {
  const { activateUserSnap } = useUserSnap();

  const user = useCurrentUser();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).userEmail = user.email || '';
  }, [user.email]);

  useEffect(() => {
    if (
      import.meta.env.MODE === 'production' ||
      import.meta.env.MODE === 'mobile'
    ) {
      activateUserSnap();
    }
  }, [activateUserSnap]);

  return null;
};

export default UserSnapProvider;
