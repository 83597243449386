import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { IProjectShiftWorkplanner } from 'interfaces/ProjectShift.interface';
import { getContactsFromShiftsWithStatus } from 'components/Timeline/helper/shiftTimeline.helper';
import {
  ILeftLegendContentProps,
  ILeftLegendShiftCollectionProps,
  ILeftLegendShiftProps,
} from './LeftLegend.type';
import {
  LabelStyled,
  LabelWrapperStyled,
  LegendInnerBottomWrapperStyled,
  LegendInnerStyled,
  LegendInnerTopStyled,
  LegendWrapperStyled,
} from './LeftLegend.styled';
import ContactSummary from '../ContactSummary';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';

const LeftLegendContent: FC<ILeftLegendContentProps> = ({
  label,
  width,
  children,
  leftOffset,
}) => {
  return (
    <LegendWrapperStyled $width={width} $leftOffset={leftOffset}>
      <LegendInnerStyled>
        <LegendInnerTopStyled>
          <LabelWrapperStyled>
            <LabelStyled>{label}</LabelStyled>
          </LabelWrapperStyled>
        </LegendInnerTopStyled>
        <LegendInnerBottomWrapperStyled>
          {children}
        </LegendInnerBottomWrapperStyled>
      </LegendInnerStyled>
    </LegendWrapperStyled>
  );
};

export const ShiftLeftLegend: FC<ILeftLegendShiftProps> = ({
  width,
  items,
  groupKey,
  groupLabel,
  contactToCompare,
  onChooseContact,
  leftOffset,
  missionId,
}) => {
  const { t } = useTranslation('general');

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const label = t(`workplanner.timeline.grouplabel.${groupKey}`, {
    groupLabel,
  });

  const shiftItems: IProjectShiftWorkplanner[] = items.map((item) => {
    return item.item;
  });

  const contacts = getContactsFromShiftsWithStatus(shiftItems);

  return (
    <LeftLegendContent label={label} width={width} leftOffset={leftOffset}>
      {enableClientContacts && (
        <ContactSummary
          contacts={contacts}
          keyIdentifier={label}
          contactToCompare={contactToCompare}
          onChooseContact={onChooseContact}
        />
      )}
    </LeftLegendContent>
  );
};

export const ShiftCollectionLeftLegend: FC<ILeftLegendShiftCollectionProps> = ({
  width,
  items,
  groupKey,
  groupLabel,
  contactToCompare,
  onChooseContact,
  leftOffset,
  missionId,
}) => {
  const { t } = useTranslation('general');

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const label = t(`workplanner.timeline.grouplabel.${groupKey}`, {
    groupLabel,
  });

  const shiftItems: IProjectShiftWorkplanner[] = [];
  items.forEach((item) => {
    shiftItems.push(...item.item);
  });

  const contacts = getContactsFromShiftsWithStatus(shiftItems);

  return (
    <LeftLegendContent label={label} width={width} leftOffset={leftOffset}>
      {enableClientContacts && (
        <ContactSummary
          contacts={contacts}
          keyIdentifier={label}
          contactToCompare={contactToCompare}
          onChooseContact={onChooseContact}
        />
      )}
    </LeftLegendContent>
  );
};
