import moment, { Moment } from 'moment';

import { TWorkplannerShift } from 'interfaces/ProjectShift.interface';
import { TResolutionType } from 'components/Timeline/interfaces/timelineResolutions.interface';
import { ITimelineDisplayItem } from 'components/Timeline/interfaces/timeline.interface';
import { workingDays, workingHours } from '../consts/timebar.consts';

/**
 * function to check, if a year is a leap year
 */
export const isLeapYear = (year: number): boolean => {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
};

export const isWorkingHour = (momentDate: Moment): boolean => {
  return workingHours.includes(momentDate.hour());
};

export const isWorkDay = (momentDate: Moment): boolean => {
  return workingDays.includes(moment(momentDate).weekday());
};

export const isInWorkingTime = (
  resolution: TResolutionType,
  momentDate: Moment
): boolean => {
  switch (resolution) {
    case 'hour':
      return isWorkingHour(momentDate);
    case 'day':
    case 'month':
      return isWorkDay(momentDate);
    default:
      return false;
  }
};

export const getPixelIncrement = (
  date: Moment,
  resolutionType: TResolutionType,
  start: Moment,
  end: Moment,
  offset = 0,
  width = 0
): number => {
  const startToEndMs = end.diff(start, 'milliseconds');
  const pixelsPerMs = width / startToEndMs;
  const daysInYear = isLeapYear(date.year()) ? 366 : 365;
  let inc = width;
  switch (resolutionType) {
    case 'year':
      inc = pixelsPerMs * 1000 * 60 * 60 * 24 * (daysInYear - offset);
      break;
    case 'month':
      inc = pixelsPerMs * 1000 * 60 * 60 * 24 * (date.daysInMonth() - offset);
      break;
    case 'day':
      inc = pixelsPerMs * 1000 * 60 * 60 * (24 - offset);
      break;
    case 'hour':
      inc = pixelsPerMs * 1000 * 60 * (60 - offset);
      break;
    default:
      break;
  }
  return Math.min(inc, width);
};

/**
 * returns duration interval of two moment objects as hour number
 */
export const getDurationAsHoursByMoment = (
  startMoment: Moment,
  endMoment: Moment
): number => {
  return moment.duration(endMoment.diff(startMoment)).asHours();
};

/**
 * returns duration interval of two moment objects as day number
 */
export const getDurationAsDaysByMoment = (
  startMoment: Moment,
  endMoment: Moment
): number => {
  return moment.duration(endMoment.diff(startMoment)).asDays();
};

/**
 * returns duration interval of two date strings as hour number
 */
export const getDurationAsHoursByDateString = (
  start: string,
  end: string
): number => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return getDurationAsHoursByMoment(startMoment, endMoment);
};

/**
 * returns the summed up hours of a ProjectShifts array
 */
export const getSummedUpHoursByShifts = (
  items: TWorkplannerShift[]
): number => {
  let hours = 0;
  items.forEach((item) => {
    hours += getDurationAsHoursByDateString(item.start, item.end);
  });

  return hours;
};

/**
 * returns the summed up hours of a Timeline Display Item array
 */
export const getSummedUpHoursByTimelineDisplayItems = (
  items: ITimelineDisplayItem<unknown>[]
): number => {
  let hours = 0;
  items.forEach((item) => {
    hours += getDurationAsHoursByMoment(item.start, item.end);
  });

  return hours;
};
