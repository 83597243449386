import { styled } from 'styled-components';
import { Utils, theme } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const WorkPlannerWrapperStyled = styled.div`
  ${flexFn()}
  height: 100%;
  padding: ${pxToRem([10, 0])};
  background-color: ${theme.colors.greyShades.grey6};
  position: relative;
  overflow: hidden;
`;

export const MainPanelStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: 'header' 'workplanner-body';
  grid-template-rows: min-content auto ${pxToRem(48)};
  height: 100%;
  overflow: auto;
`;

export const BodyWrapperStyled = styled.div`
  height: 100%;
  grid-area: workplanner-body;
  overflow-x: auto;
  overflow-y: hidden;
`;
