import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Grid,
  Icons,
  OverviewCardCounter,
  OverviewCardWrapper,
  Theme,
} from 'team-hero-ui';

import useGetClientLoginDashboardWidget from 'components/ClientLoginDashboard/useGetClientLoginDashboardWidget.hook';
import MissionOverviewCard from './MissionOverviewCard';
import {
  BriefingDescriptionStyled,
  BriefingTitleStyled,
  MissionOverviewTabStyled,
  MissionTasksStyled,
  OverviewDetailsStyled,
} from './MissionOverviewTab.styled';
import { IMissionOverviewTabProps } from './MissionOverviewTab.types';
import ClientLoginConversationPanel from 'components/ClientLoginDashboard/ClientLoginConversationPanel';

const MissionOverviewTab: FC<IMissionOverviewTabProps> = ({ mission }) => {
  const { t } = useTranslation('general');

  const {
    applicationsPendingByMissionId,
    timetrackingsPendingByMissionId,
    ratingsPendingByMissionId,
  } = useGetClientLoginDashboardWidget({
    skip: !mission,
  });

  const timetrackingsPendingCount = timetrackingsPendingByMissionId(mission.id);
  const ratingsPendingCount = ratingsPendingByMissionId(mission.id);
  const applicationsPendingCount = applicationsPendingByMissionId(mission.id);

  return (
    <MissionOverviewTabStyled>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OverviewDetailsStyled>
            <MissionOverviewCard mission={mission} />
            <MissionTasksStyled>
              <OverviewCardWrapper>
                <OverviewCardCounter
                  title={t('clientLogin.dashboard.tasks.timetrackings')}
                  count={timetrackingsPendingCount}
                  right={-25}
                  bottom={-20}
                  counterColor={
                    timetrackingsPendingCount > 0
                      ? Theme.colors.primary.red
                      : Theme.colors.primary.green
                  }
                  icon={
                    <Icons.TimeTrackingCheckIcon
                      svgColor={Theme.colors.greyShades.grey5}
                      svgSize={100}
                    />
                  }
                />
              </OverviewCardWrapper>
              <OverviewCardWrapper>
                <OverviewCardCounter
                  title={t('clientLogin.dashboard.tasks.ratings')}
                  count={ratingsPendingCount}
                  right={-25}
                  bottom={-15}
                  counterColor={
                    ratingsPendingCount > 0
                      ? Theme.colors.primary.red
                      : Theme.colors.primary.green
                  }
                  icon={
                    <Icons.StarIcon
                      svgColor={Theme.colors.greyShades.grey5}
                      svgSize={100}
                    />
                  }
                />
              </OverviewCardWrapper>
              <OverviewCardWrapper>
                <OverviewCardCounter
                  title={t('clientLogin.dashboard.tasks.applications')}
                  count={applicationsPendingCount}
                  right={-30}
                  bottom={-15}
                  counterColor={
                    applicationsPendingCount > 0
                      ? Theme.colors.primary.red
                      : Theme.colors.primary.green
                  }
                  icon={
                    <Icons.PollOK
                      svgColor={Theme.colors.greyShades.grey5}
                      svgSize={100}
                    />
                  }
                />
              </OverviewCardWrapper>
            </MissionTasksStyled>
            <Card
              title={
                <BriefingTitleStyled>
                  <Icons.BriefingIcon svgSize={24} /> {t('details.briefing')}
                </BriefingTitleStyled>
              }
            >
              <BriefingDescriptionStyled
                dangerouslySetInnerHTML={{ __html: mission.description || '' }}
              />
            </Card>
          </OverviewDetailsStyled>
        </Grid>
        <Grid item xs={6}>
          <ClientLoginConversationPanel
            defaultView='conversation'
            conversationType='missionLeader'
            mission={mission}
            leader={mission.leader}
            conversationsFilter={[
              { key: 'relatedMission', operator: 'AND', value: mission.id },
            ]}
            height='calc(100dvh - 108px)'
          />
        </Grid>
      </Grid>
    </MissionOverviewTabStyled>
  );
};

export default MissionOverviewTab;
