import { Icons, Theme } from 'team-hero-ui';

import { CellContainerStyled } from './TimetrackingToDoCellRenderer.styled';

export const TimetrackingToDoCellRenderer = (
  isExternallyApproved = false,
  hasTimetrackingsForExternalApproval = false,
  hasDraftTimetrackings = false
): JSX.Element => {
  const iconSize = 20;

  return (
    <CellContainerStyled>
      {!isExternallyApproved && hasTimetrackingsForExternalApproval && (
        <Icons.TimeTrackingSignedV1ExIcon
          svgColor={Theme.colors.primary.red}
          svgSize={iconSize}
        />
      )}
      {hasDraftTimetrackings ? (
        <Icons.ClockIcon
          svgColor={Theme.colors.primary.red}
          svgSize={iconSize}
        />
      ) : (
        <Icons.CheckIconV1
          svgColor={Theme.colors.primary.grey}
          svgSize={iconSize}
        />
      )}
    </CellContainerStyled>
  );
};
