import { useEffect, useMemo } from 'react';
import { Loader } from 'team-hero-ui';

import Table from 'components/Table';
import type { IId } from 'interfaces/IId.interface';
import PaginationFooter from 'components/PaginationFooter';
import { useColumnList } from './hooks/useColumnList.hook';
import {
  BulkBottomStyled,
  BulkTopStyled,
  MainPanelWorkPlannerStyled,
  TableBodyWrapperStyled,
  TableViewWorkPlannerStyled,
  TableWrapperStyled,
} from './TableView.styled';
import defaultItemsPerPageDropdownItems from 'components/PaginationFooter/helper/defaultItemsPerPageDropdownItems';
import calculatePagesCount from 'components/PaginationFooter/helper/calculatePagesCount.helper';
import { IUsePaginationReturn } from 'hooks/usePagination.hook';
import { ITableViewProps } from './TableView.types';

interface ITableViewWorkplannerProps<T extends IId> extends ITableViewProps<T> {
  pagination: IUsePaginationReturn;
}

const TableViewWorkplanner = <T extends IId>({
  data,
  columns,
  pagination,
  columnsOffset,
  rowIdMenuOpen,
  onRowClick,
  selectedId,
  sorting,
  itemsTotalCount,
  bulkEditRow,
  editRowComponent,
  isLoading = false,
  paginationSelector,
  setColumnListDispatch,
  itemsPerPageOptions,
}: ITableViewWorkplannerProps<T>): JSX.Element => {
  const { tableColumns } = useColumnList({
    columns,
    columnsOffset,
    paginationSelector,
    setColumnListDispatch,
  });

  useEffect(() => {
    if (itemsTotalCount > 0 && data.length === 0 && pagination) {
      pagination.onResetPage();
    }
  }, [data, itemsTotalCount, pagination]);

  const paginationRenderer = useMemo(() => {
    if (!pagination) {
      return null;
    }
    return (
      <PaginationFooter
        dropdownProps={{
          items: itemsPerPageOptions || defaultItemsPerPageDropdownItems,
          onChange: (value: number) => pagination.onSetItemsPerPage(value),
          selected: pagination.itemsPerPage,
        }}
        paginationProps={{
          count: calculatePagesCount(pagination.itemsPerPage, itemsTotalCount),
          page: pagination.page,
          onChange: pagination.onChangePage,
        }}
      />
    );
  }, [itemsPerPageOptions, itemsTotalCount, pagination]);

  return (
    <TableViewWorkPlannerStyled>
      <MainPanelWorkPlannerStyled data-test-id='workplanner-table-main-panel'>
        <TableBodyWrapperStyled>
          <BulkTopStyled>{bulkEditRow('top')}</BulkTopStyled>
          <TableWrapperStyled>
            {isLoading && <Loader loaderType='absolute' />}
            {data && (
              <Table
                columns={tableColumns}
                data={data}
                editRowComponent={editRowComponent}
                rowIdMenuOpen={rowIdMenuOpen}
                onRowClick={onRowClick}
                isMin={false}
                selectedId={selectedId}
                sorting={sorting}
                displayHeader
              />
            )}
          </TableWrapperStyled>
          <BulkBottomStyled>{bulkEditRow('bottom')}</BulkBottomStyled>
        </TableBodyWrapperStyled>
        {paginationRenderer}
      </MainPanelWorkPlannerStyled>
    </TableViewWorkPlannerStyled>
  );
};

export default TableViewWorkplanner;
