import { Icons, Theme } from 'team-hero-ui';

import { TimetrackingStatusStyled } from './TimetrackingStatusCellRenderer.styled';

export const TimetrackingStatusCellRenderer = (
  hasLocation?: boolean,
  enableGeolocation?: boolean
): JSX.Element => {
  return (
    <TimetrackingStatusStyled>
      {hasLocation === false && enableGeolocation && (
        <Icons.TimeTrackingGeoLocationIcon
          svgColor={Theme.colors.primary.red}
          svgSize={24}
        />
      )}
    </TimetrackingStatusStyled>
  );
};
