import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import {
  ResultsSectionContentStyled,
  ResultsSectionStyled,
  ResultsSectionTitleStyled,
  ResultsSectionWrapperStyled,
} from './ResultsSection.styled';
import { ResultRow } from '../ResultRow/ResultRow';
import { TTabsView } from '../Tabs/Tabs';
import type { IId } from 'interfaces/IId.interface';

interface IResultsSectionProps<T> {
  data: T[];
  columns: IColumnDefinitionType<T>[];
  viewType: TTabsView;
  onClose: () => void;
  title?: string;
  pagination?: ReactElement;
}
export const ResultsSection = <T extends IId>({
  data,
  columns,
  title,
  pagination,
  viewType,
  onClose,
}: IResultsSectionProps<T>) => {
  const navigate = useNavigate();
  const withPagination = !!pagination;
  const handleOnClick = useCallback(
    (id: number) => {
      onClose();
      navigate(`/work/?view=${viewType}&mission=${id}`);
    },
    [navigate, onClose, viewType]
  );

  return (
    <ResultsSectionWrapperStyled $withPagination={withPagination}>
      {!!title && (
        <ResultsSectionTitleStyled>{title}</ResultsSectionTitleStyled>
      )}
      <ResultsSectionContentStyled $withPagination={withPagination}>
        <ResultsSectionStyled $withPagination={withPagination}>
          {!!data.length &&
            data.map((item) => {
              const handleClick = () => {
                handleOnClick(item.id);
              };
              return (
                <ResultRow
                  row={item}
                  columns={columns}
                  key={item.id}
                  onClick={handleClick}
                />
              );
            })}
        </ResultsSectionStyled>
        {withPagination && pagination}
      </ResultsSectionContentStyled>
    </ResultsSectionWrapperStyled>
  );
};
