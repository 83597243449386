import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

interface IStatusStyledProps {
  $statusColor: string;
  $disabled?: boolean;
}

export const StatusStyled = styled.div<IStatusStyledProps>`
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: ${pxToRem(14)};
    height: ${pxToRem(14)};
    background-color: ${({ $statusColor }) => $statusColor};
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.greyShades.grey6 : theme.colors.primary.white};
    border-top-left-radius: ${pxToRem(5)};
    border-bottom-left-radius: ${pxToRem(5)};
  }
`;
