import { Theme } from 'team-hero-ui';

import { TProjectShiftStatus } from 'interfaces/ProjectShift.interface';

export const getStatusColor = (status: TProjectShiftStatus): string => {
  switch (status) {
    case 'open':
      return Theme.colors.primary.red;
    case 'planned':
      return Theme.colors.others.blue.blue1;
    case 'booked':
      return Theme.colors.others.yellow.yellow1;
    case 'confirmed':
      return Theme.colors.others.green.green1;
    case 'deactivated':
    default:
      return Theme.colors.greyShades.grey4;
  }
};
