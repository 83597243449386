import { ReactElement } from 'react';

import type { IId } from 'interfaces/IId.interface';
import { TableBody } from './components/TableBody/TableBody';
import { TableHeader } from './components/TableHeader/TableHeader';
import { ITableProps } from './Table.types';

export const Table = <T extends IId>({
  data,
  columns,
  rowIdMenuOpen,
  sorting,
  defaultCellMinWidth = '200px',
  editRowComponent,
  onRowClick,
  isMin = false,
  selectedId,
  headerLeftAdditional,
  styleType = 'default',
  rowCollapsibleContentRenderer,
  topRowRenderer,
  bottomRowRenderer,
  displayHeader = true,
}: ITableProps<T>): ReactElement => {
  return (
    <>
      {displayHeader && data.length > 0 && (
        <TableHeader
          sorting={sorting}
          columns={columns}
          defaultCellWidth={defaultCellMinWidth}
        />
      )}
      <TableBody
        data={data}
        columns={columns}
        rowIdMenuOpen={rowIdMenuOpen}
        editRowComponent={editRowComponent}
        onRowClick={onRowClick}
        isMin={isMin}
        selectedId={selectedId}
        headerLeftAdditional={headerLeftAdditional}
        styleType={styleType}
        rowCollapsibleContentRenderer={rowCollapsibleContentRenderer}
        topRowRenderer={topRowRenderer}
        bottomRowRenderer={bottomRowRenderer}
      />
    </>
  );
};

export default Table;
