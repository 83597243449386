import { Utils } from 'team-hero-ui';

import { ITableColumn } from 'interfaces/Table/DataTableColumn.interface';

const { pxToRem } = Utils;

const getCalculatedWidth = (
  width: string | undefined,
  minWidth: string | undefined
): string => {
  const defaultWidth = '200px';
  if (width) {
    return pxToRem(width);
  }
  if (minWidth) {
    const parsedToRem = pxToRem(minWidth);
    return `minmax(${parsedToRem}, 100fr) `;
  }
  return pxToRem(defaultWidth);
};

export const getGridColsWidth = (columns: ITableColumn[]): string => {
  const colWidths: string[] = [];
  columns.forEach(({ visibility, width, minWidth }) => {
    if (visibility) {
      const calculatedWidth = getCalculatedWidth(width, minWidth);
      colWidths.push(calculatedWidth);
    }
  });
  return colWidths.join(' ');
};
