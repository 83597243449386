import { useCallback } from 'react';

import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { TruncatedCellStyled } from 'components/Table/components/TableBody/TableBody.styled';
import {
  StyledResultInnerRow,
  StyledResultRow,
  StyledRowCell,
} from './ResultRow.styled';
import type { IId } from 'interfaces/IId.interface';

interface IResultRowProps<T extends IId> {
  row: T;
  columns: IColumnDefinitionType<T>[];
  onClick: () => void;
}

export const ResultRow = <T extends IId>({
  row,
  columns,
  onClick,
}: IResultRowProps<T>): JSX.Element => {
  const handleOnRowClick = useCallback(() => {
    return;
  }, []);

  const renderCell = useCallback(
    (column: IColumnDefinitionType<T>) => {
      const getCell = () => {
        if (column.cellType === 'custom' && column.cellRenderer) {
          return column.cellRenderer(row, handleOnRowClick, false);
        }

        return (
          <TruncatedCellStyled>
            {(row[column.key as keyof T] as unknown as string) || '-'}
          </TruncatedCellStyled>
        );
      };

      return (
        <StyledRowCell
          $isFirst={column.key === 'status'}
          key={`cell-${row.id}-${column.key}`}
          $disabled={false}
        >
          {getCell()}
        </StyledRowCell>
      );
    },

    [handleOnRowClick, row]
  );

  const checkIfColumnShouldBeRendered = useCallback(
    (column: IColumnDefinitionType<T>) => {
      return column.visibility;
    },
    []
  );

  return (
    <StyledResultRow key={`inner-row-${row.id}`} onClick={onClick}>
      <StyledResultInnerRow columns={columns}>
        {columns.map((column) => {
          return checkIfColumnShouldBeRendered(column)
            ? renderCell(column)
            : null;
        })}
      </StyledResultInnerRow>
    </StyledResultRow>
  );
};
