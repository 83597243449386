import { ReactElement } from 'react';

import {
  StyledTableCell,
  TruncatedCellStyled,
} from '../TableBody/TableBody.styled';
import { TTableStyleValue } from 'components/Table/Table.types';
import type { IId } from 'interfaces/IId.interface';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';

interface ITableCellProps<T> {
  columnDefinition: IColumnDefinitionType<T>;
  rowItem: T;
  onRowClick?: (id: number) => void;
  selectedId?: number;
  isMin: boolean;
  styleType?: TTableStyleValue;
}

export const TableCell = <T extends IId>({
  columnDefinition,
  rowItem,
  onRowClick,
  selectedId,
  isMin,
  styleType,
}: ITableCellProps<T>): ReactElement => {
  const onClick = () => onRowClick && onRowClick(rowItem.id);

  const getCell = () => {
    const disabled =
      typeof selectedId !== 'undefined' && rowItem.id !== selectedId;

    if (
      columnDefinition.cellType === 'custom' &&
      columnDefinition.cellRenderer
    ) {
      return columnDefinition.cellRenderer(rowItem, onClick, disabled);
    }

    return (
      <TruncatedCellStyled>
        <>{rowItem[columnDefinition.key as keyof T] || '-'}</>
      </TruncatedCellStyled>
    );
  };

  return (
    <StyledTableCell
      $isFirst={['beginSeparator', 'selectionStatus', 'status'].includes(
        columnDefinition.key
      )}
      $firstChildBorderRadius={['avatarFirstChild'].includes(
        columnDefinition.key
      )}
      key={`cell-${rowItem.id}-${columnDefinition.key}`}
      $disabled={selectedId !== rowItem.id && isMin}
      $styleType={styleType}
      data-component={'table-cell'}
      data-test-id={`table-cell-${columnDefinition.key}`}
    >
      {getCell()}
    </StyledTableCell>
  );
};
