import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, Tag, Theme } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { useGetProjectMissionsQuery } from 'services/teamHeroApi.service';
import { DownloadButtonStyled } from './SelectMissionDropdown.styled';
import { ISelectMissionDropdownItemProps } from './SelectMissionDropdown.types';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';

const SelectMissionDropdownItems: FC<ISelectMissionDropdownItemProps> = ({
  missions,
  onClick,
}) => {
  return (
    <>
      {missions.map((mission) => (
        <DropdownItem key={mission.id}>
          <DownloadButtonStyled onClick={() => onClick(mission)}>
            <Tag>{mission.project.title}</Tag>
            {mission.title}
          </DownloadButtonStyled>
        </DropdownItem>
      ))}
    </>
  );
};

export const SelectMissionDropdown: FC = () => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  const [closed, setClosed] = useState(false);

  const { currentUserContact } = useCurrentUserContact();

  const onMissionSelect = useCallback(
    (mission: IProjectMission) => {
      setClosed(true);
      navigate(`/work/${mission.id}/details`);
    },
    [navigate]
  );

  const { data: missions, isLoading } = useGetProjectMissionsQuery(
    {
      pagination: false,
      filters: [
        {
          key: 'contact',
          operator: 'AND',
          value: currentUserContact?.id || null,
        },
      ],
    },
    { skip: !currentUserContact?.id }
  );
  const projectMissionsItems = missions?.items;

  return (
    <Dropdown
      styleType='border-disabled'
      iconColor={Theme.colors.greyShades.grey2}
      borderColor={Theme.colors.greyShades.grey5}
      disabled={!projectMissionsItems?.length}
      dataTestId='select-mission-dropdown'
      closed={closed}
      handleCloseCb={() => setClosed(false)}
    >
      {isLoading ? (
        <div>{t('common.loading')} ...</div>
      ) : (
        <SelectMissionDropdownItems
          missions={projectMissionsItems || []}
          onClick={onMissionSelect}
        />
      )}
    </Dropdown>
  );
};
