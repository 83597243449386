import { useEffect, useState } from 'react';

import { getImageUrls, TSourceValue } from 'helpers/file/getImageUrl.helper';
import { TThumbnailSize } from 'helpers/file/file.helpers.types';

interface IUseGetImageUrlProps<T> {
  images?: string | string[];
  imageType?: TSourceValue;
  additionalRefreshTrigger?: T;
  thumbnailSize?: TThumbnailSize;
}

interface IUseGetImageUrlReturn {
  imageUrls: string | string[];
  isLoading: boolean;
}

const useGetImageUrl = <T>({
  images,
  imageType,
  additionalRefreshTrigger,
  thumbnailSize,
}: IUseGetImageUrlProps<T>): IUseGetImageUrlReturn => {
  const [imageUrls, setImageUrls] = useState<string | string[]>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    const fetchImages = async () => {
      setIsLoading(true);
      const urls = await getImageUrls(
        images,
        imageType || 'file',
        thumbnailSize
      );
      if (isSubscribed) {
        setImageUrls(urls);
        setIsLoading(false);
      }
    };

    fetchImages();

    return () => {
      isSubscribed = false;
      setIsLoading(false);
    };
  }, [images, imageType, additionalRefreshTrigger, thumbnailSize]);

  return { imageUrls, isLoading };
};

export default useGetImageUrl;
