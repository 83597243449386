import moment, { Moment } from 'moment';

import { IWorkPlannerSettingState } from 'components/WorkPlanner/WorkPlanner.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';

const defaultWorkPlannerSettingsState: IWorkPlannerSettingState = {
  startDate: moment().startOf('isoWeek'),
  endDate: moment().startOf('month').add(1, 'month'),
  timelineSize: '1week',
  viewMode: 'timeline',
};

const getWorkplannerSettingsByMission = (
  currentDate: Moment,
  mission: IProjectMission
): IWorkPlannerSettingState => {
  // case 1 - mission is in the past
  if (moment(mission.end || undefined).isBefore(currentDate)) {
    return {
      ...defaultWorkPlannerSettingsState,
      startDate: moment(mission.end || undefined).startOf('isoWeek'),
    };
  }

  // case 2 - mission is active
  if (
    moment(mission.start || undefined).isBefore(currentDate) &&
    moment(mission.end || undefined).isAfter(currentDate)
  ) {
    return {
      ...defaultWorkPlannerSettingsState,
      startDate: moment(currentDate).startOf('isoWeek'),
    };
  }

  // case 3 - mission is in the future
  if (moment(mission.start || undefined).isAfter(currentDate)) {
    return {
      ...defaultWorkPlannerSettingsState,
      startDate: moment(mission.start || undefined).startOf('isoWeek'),
    };
  }

  return {
    ...defaultWorkPlannerSettingsState,
    startDate: moment(mission.start || undefined).startOf('isoWeek'),
  };
};

export default getWorkplannerSettingsByMission;
