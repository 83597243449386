import { IMessageConversation } from 'interfaces/MessageConversation.interface';
import { checkEmbedIsMessageConversation } from 'helpers/embed/embedTypeCheck.helper';

export const getConversationIdFromIriOrEmbed = (
  conversation: string | IMessageConversation | null
): number | undefined => {
  if (!conversation) {
    return undefined;
  }
  if (checkEmbedIsMessageConversation(conversation)) {
    const id = parseInt(
      conversation['@id'].replace('/api/message_conversations/', ''),
      10
    );
    if (isNaN(id)) {
      return undefined;
    }
    return id;
  }

  const id = parseInt(
    conversation.replace('/api/message_conversations/', ''),
    10
  );
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};
