import moment from 'moment';

import { TSnapResolutionType } from 'components/Timeline/Timeline.type';
import {
  ITimelineDisplayItem,
  ITimelineItem,
  TTimelineItemVariant,
} from 'components/Timeline/interfaces/timeline.interface';

/**
 * calculates the time start snap time, for current resolution settings
 */
const calculateSnapTimeStart = (
  snapResolution: TSnapResolutionType,
  startTime: moment.Moment,
  minStartTime: moment.Moment
): moment.Moment => {
  switch (snapResolution) {
    case 'hour':
      if (startTime.isBefore(minStartTime)) {
        return minStartTime.clone();
      }
      return startTime.clone().startOf('hour');
    case 'month':
    case 'day':
    case '1day':
    default:
      if (startTime.isBefore(minStartTime)) {
        return minStartTime.clone();
      }
      return startTime.clone().startOf('day');
  }
};

/**
 * calculates the time end snap time, for current resolution settings
 */
const calculateSnapTimeEnd = (
  snapResolution: TSnapResolutionType,
  startTime: moment.Moment,
  endTime: moment.Moment,
  maxEndTime: moment.Moment
): moment.Moment => {
  // we need to substract one minute, to also make sure, that this also works for exact same dates
  if (maxEndTime.clone().subtract(1, 'minute').isBefore(endTime)) {
    return maxEndTime.clone();
  }
  switch (snapResolution) {
    case 'hour':
      return endTime.clone().subtract(10, 'second').endOf(snapResolution);
    case 'day':
      return endTime.clone().subtract(10, 'second').endOf(snapResolution);
    case '1day':
      return startTime.clone().endOf('day');
    default:
      return endTime.clone().endOf(snapResolution);
  }
};

export const filterDisplayItemsByTimeFrame = <T extends TTimelineItemVariant>(
  items: ITimelineDisplayItem<T>[],
  start: moment.Moment,
  end: moment.Moment
): ITimelineDisplayItem<T>[] => {
  return items.filter(
    (item) => !item.end.isBefore(start) && !item.start.isAfter(end)
  );
};

/**
 * add row offset values to the displayItems
 */
const getDisplayItemsWithRowOffset = <T extends TTimelineItemVariant>(
  items: ITimelineDisplayItem<T>[]
): ITimelineDisplayItem<T>[] => {
  const displayItems = [];
  let rowOffset = 0;
  while (items.length > 0) {
    let lastEnd = null;
    for (let i = items.length - 1; i >= 0; i -= 1) {
      if (lastEnd === null || items[i].displayStart >= lastEnd) {
        const item = { ...items[i], rowOffset };
        displayItems.push(item);
        items.splice(i, 1);
        lastEnd = item.displayEnd;
      }
    }
    rowOffset += 1;
  }
  return displayItems;
};

/**
 * takes timelineItems and return new array, sorted by startdate in reversed order
 */
const getSortedTimelineItems = <T extends TTimelineItemVariant>(
  items: ITimelineItem<T>[]
): ITimelineItem<T>[] => {
  return [...items].sort(
    (firstItem, secondItem) => secondItem.start.unix() - firstItem.start.unix()
  );
};

/**
 * takes timelineDisplayItems and return new array, sorted by startdate in reversed order
 */
const getSortedDisplayTimelineItems = <T extends TTimelineItemVariant>(
  items: ITimelineDisplayItem<T>[]
): ITimelineDisplayItem<T>[] =>
  [...items].sort(
    (firstItem, secondItem) => secondItem.start.unix() - firstItem.start.unix()
  );

/**
 * transforms the Timeline Items and adds values for displaying start - end
 */
export const getDisplayItems = <T extends TTimelineItemVariant>(
  items: ITimelineItem<T>[],
  snapResolution: TSnapResolutionType,
  timelineStart: moment.Moment,
  timelineEnd: moment.Moment
): ITimelineDisplayItem<T>[] => {
  const displayItems: ITimelineDisplayItem<T>[] = [];

  const sortedItems = getSortedTimelineItems(items);

  sortedItems.forEach((item) => {
    const displayStart = calculateSnapTimeStart(
      snapResolution,
      item.start,
      timelineStart
    );
    const displayEnd = calculateSnapTimeEnd(
      snapResolution,
      item.start,
      item.end,
      timelineEnd
    );

    displayItems.push({
      ...item,
      displayStart,
      displayEnd,
    });
  });

  return getDisplayItemsWithRowOffset([...displayItems]);
};

/**
 * Use to find the max number of overlapping items of a row, given a set of items
 */
export const getMaxOverlappingItems = <T extends TTimelineItemVariant>(
  items: ITimelineDisplayItem<T>[]
): number => {
  let max = 0;
  const sortedItems = getSortedDisplayTimelineItems(items);

  while (sortedItems.length > 0) {
    let lastEnd = null;
    for (let i = sortedItems.length - 1; i >= 0; i -= 1) {
      const start = sortedItems[i].displayStart;

      const end = sortedItems[i].displayEnd;

      if (lastEnd === null || start >= lastEnd) {
        lastEnd = end;
        sortedItems.splice(i, 1);
      }
    }
    max += 1;
  }
  return Math.max(max, 1);
};
