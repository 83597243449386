import { TMomentFormats } from 'hooks/useDateFormat.hook';

interface ITimebarFormat {
  [key: string]: {
    short: TMomentFormats;
    long: TMomentFormats;
    top?: TMomentFormats;
  };
}

/**
 * Default timebar format
 */
export const timebarFormat: ITimebarFormat = {
  millisecond: {
    short: 'milliseconds', // 001
    long: 'millisecondsLong', // 01:10.001
  },
  second: {
    short: 'seconds', // 10
    long: 'secondsLong', // 01:10
  },
  minute: {
    short: 'minuteShort', // 01
    long: 'time', // 9:01
  },
  hour: {
    short: 'hourShort', // 9:01
    long: 'time', // 9:01
  },
  day: {
    short: 'dayNumber', // 1
    long: 'dateWithDayNoYear', // Sun 1st
    top: 'dayTwoLetters', // Su
  },
  month: {
    short: 'month', // 02
    long: 'monthLong', // January
  },
  year: {
    short: 'year', // 2018
    long: 'year', // 2018
  },
};

export const workingHours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

export const workingDays = [0, 1, 2, 3, 4];
