import { FC, useEffect, useState } from 'react';
import { Select, SelectItem } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { ISelectValue } from 'interfaces/Data/SelectValue.interface';
import { ISelectRendererProps } from 'components/FilterPanel/FilterPanel.type';
import { useGetSelectExternalOptionValues } from 'hooks/getSelectExternalOptionValues/getSelectExternalOptionValues.hook';
import { createOptionSelectGroups } from 'components/Modals/AddEditModal/ModalSection/fields/SelectField/helper/selectOptions.helper';
import { IOptionGroupWithItems } from 'components/Modals/AddEditModal/ModalSection/fields/SelectField/SelectField.types';

export const SelectRenderer: FC<ISelectRendererProps> = ({
  label,
  staticValues,
  externalValuesType,
  value,
  onChange,
  styleType,
  disabled = false,
  disableNoValue = false,
  optionGroups = [],
}) => {
  const { t } = useTranslation('general');
  const [items, setItems] = useState<ISelectValue[]>([]);
  const selectLabel = t('selectValue', { filterLabel: label });

  const { data: externalSelectItems } = useGetSelectExternalOptionValues(
    externalValuesType,
    false
  );

  useEffect(() => {
    if (staticValues) {
      setItems(staticValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOptions = externalValuesType ? externalSelectItems : items;

  const selectItemList = (): JSX.Element[] => {
    // if optionGroups are defined, return the grouped selectItems
    // not grouped items, will be put to others group
    if (optionGroups.length > 0) {
      const optionSelectGroups = createOptionSelectGroups(
        optionGroups,
        selectOptions,
        t
      );
      const optionsReturn: JSX.Element[] = [];
      optionSelectGroups.forEach((optionGroup: IOptionGroupWithItems) => {
        optionGroup.values.forEach((item) => {
          optionsReturn.push(
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          );
        });
      });
      return optionsReturn;
    }
    // if no optionGroups are defined, return the default selectItems
    return selectOptions.map((item) => {
      return (
        <SelectItem key={item.value} value={item.value}>
          {item.label}
        </SelectItem>
      );
    });
  };

  return (
    <Select
      disabled={disabled}
      label={selectLabel}
      value={value}
      onChange={onChange}
      styleType={styleType === 'modal' ? styleType : 'filter'}
    >
      {!disableNoValue && (
        <SelectItem key='noValue' value=''>
          {t('select.noValue')}
        </SelectItem>
      )}
      {selectItemList()}
    </Select>
  );
};
