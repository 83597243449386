import { FC, useEffect, useState } from 'react';
import { MessageAttachment } from 'team-hero-ui';

import { getImageUrls } from 'helpers/file/getImageUrl.helper';
import { useGetMessageFileEntityQuery } from 'services/teamHeroApi.service';
import useGetFile from 'hooks/useGetFile';
import { IMessageFileEmbed } from 'interfaces/Message.interface';
import { getMessageFileIdFromIriOrEmbed } from 'helpers/message/getMessageFileIdFromIriOrEmbed.helper';

interface IMessageAttachmentWrapperProps {
  attachment: string | IMessageFileEmbed;
  isSentMessage: boolean;
}

const MessageAttachmentWrapper: FC<IMessageAttachmentWrapperProps> = ({
  attachment,
  isSentMessage,
}) => {
  const [messageImageUrl, setMessageImageUrl] = useState<string | undefined>(
    undefined
  );

  const attachmentId = getMessageFileIdFromIriOrEmbed(attachment);

  const { data, isFetching } = useGetMessageFileEntityQuery(
    {
      id: attachmentId || 0,
    },
    {
      skip: !attachmentId,
    }
  );

  useEffect(() => {
    if (data?.file && data?.mimeType?.startsWith('image')) {
      getImageUrls(data.file, 'file').then((result) => {
        setMessageImageUrl(result as string);
      });
    }

    return () => {
      setMessageImageUrl('');
    };
  }, [data]);

  const { getFile } = useGetFile(data?.originalFileName, data?.file);

  return (
    <MessageAttachment
      isSentMessage={isSentMessage}
      isLoading={isFetching}
      originalFileName={data?.originalFileName}
      file={messageImageUrl}
      mimeType={data?.mimeType}
      size={data?.size}
      onAttachmentClick={() => getFile()}
    />
  );
};

export default MessageAttachmentWrapper;
