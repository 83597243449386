import type { IMessageFileEmbed } from 'interfaces/Message.interface';
import { checkEmbedIsMessageFile } from 'helpers/embed/embedTypeCheck.helper';

export const getMessageFileIdFromIriOrEmbed = (
  attachment: string | IMessageFileEmbed
): number | undefined => {
  if (!attachment) {
    return undefined;
  }
  if (checkEmbedIsMessageFile(attachment)) {
    const embeddedId = parseInt(
      attachment['@id'].replace('/api/message_files/', ''),
      10
    );
    if (isNaN(embeddedId)) {
      return undefined;
    }
    return embeddedId;
  }
  const id = parseInt(attachment.replace('/api/message_files/', ''), 10);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};
