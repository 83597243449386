import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { TLocale } from 'interfaces/Locale.interface';
import LocalStorageService, {
  ELocalStorageKeys,
} from 'services/localStorage/localStorage.service';
import { usePostUserItemLocaleMutation } from 'services/teamHeroApi.service';

interface IUseLanguageProps {
  skip?: boolean;
}

interface IUseLanguageReturn {
  locale: TLocale;
  changeLanguage: (localeToSet: TLocale) => void;
  isLoading: boolean;
  isLocaleOverridden: boolean;
}

const changeLanguageAttributes = (locale: TLocale) => {
  const html = document.querySelector('html');
  if (html) {
    html.setAttribute('lang', locale);
    html.setAttribute('translate', 'yes');
  }
};

export const useLanguage = ({
  skip = false,
}: IUseLanguageProps): IUseLanguageReturn => {
  const { currentUser, isSuccess } = useCurrentUserContact(skip);

  const { i18n } = useTranslation();

  useEffect(() => {
    const localeOverride = LocalStorageService.get(
      ELocalStorageKeys.localeOverride
    );

    if (
      localeOverride &&
      (localeOverride === 'en' || localeOverride === 'de')
    ) {
      i18n.changeLanguage(localeOverride);
      moment.locale(localeOverride);
      if (window.Userlane) {
        window.Userlane('lang', localeOverride);
      }
    } else {
      if (
        currentUser?.locale &&
        !skip &&
        i18n.language !== currentUser.locale
      ) {
        i18n.changeLanguage(currentUser.locale);
      }
      if (currentUser?.locale && !skip) {
        moment.locale(currentUser.locale);
        if (window.Userlane) {
          window.Userlane('lang', currentUser.locale);
        }
      }
    }
  }, [currentUser, i18n, skip]);

  const [postUserItemLocale, { isLoading }] = usePostUserItemLocaleMutation();

  const changeLanguage = useCallback(
    (localeToSet: TLocale) => {
      if (isSuccess && currentUser && !skip) {
        postUserItemLocale({
          locale: localeToSet,
        });
      }
    },
    [currentUser, isSuccess, postUserItemLocale, skip]
  );

  const isLocaleOverridden =
    LocalStorageService.get(ELocalStorageKeys.localeOverride) === 'en' ||
    LocalStorageService.get(ELocalStorageKeys.localeOverride) === 'de';

  useEffect(() => {
    const lng = i18n.language;
    if (lng === 'en' || lng === 'de') {
      changeLanguageAttributes(lng);
    }
  }, [i18n.language]);

  return {
    locale: i18n.language as TLocale,
    changeLanguage,
    isLoading,
    isLocaleOverridden,
  };
};
