import { TFunction } from 'i18next';
import Joi, { ObjectSchema } from 'joi';

import type { ICreateMessageState } from './MessageInput.types';
import { validationFieldRequired } from 'config/validationStrategy/validationFieldRequired';

export const createMessageSchema = (
  t: TFunction,
  isNewMessage: boolean
): ObjectSchema<ICreateMessageState> => {
  return Joi.object({
    content: Joi.required().messages(validationFieldRequired(t)),
    subject: isNewMessage
      ? Joi.string().required().messages(validationFieldRequired(t))
      : Joi.any(),
  });
};
