import { FC } from 'react';
import { IconButton, Icons } from 'team-hero-ui';

import { useToggle } from 'hooks/useToggle.hook';
import { useElementSize } from 'hooks/useElementSize';
import { ICollapsibleGroupProps } from './CollapsibleGroup.type';
import {
  CollapsibleGroupContentWrapperStyled,
  CollapsibleGroupHeaderStyled,
  CollapsibleGroupStyled,
  RotateWrapperStyled,
  TopBarLeftLegendWrapperStyled,
  TopBarCenterWrapperStyled,
  TopBarRightLegendWrapperStyled,
  TopBarLabelStyled,
} from './CollapsibleGroup.styled';

const CollapsibleGroup: FC<ICollapsibleGroupProps> = ({
  children,
  centerWidth = 200,
  leftLegendWidth = 200,
  rightLegendWidth = 200,
  open = false,
  groupLabel,
  leftRender,
  centerRender,
  rightRender,
}) => {
  const { toggleValue: isOpen, toggle: toggleCollapse } = useToggle(open);

  const [setRef, { height }] = useElementSize();

  return (
    <CollapsibleGroupStyled data-component='collapsible-group'>
      <CollapsibleGroupHeaderStyled>
        <TopBarLeftLegendWrapperStyled $width={leftLegendWidth}>
          <div>
            <IconButton size='small' onClick={toggleCollapse}>
              <RotateWrapperStyled $isRotated={isOpen}>
                <Icons.ArrowRightIcon svgSize={10} svgColor='inherit' />
              </RotateWrapperStyled>
            </IconButton>
            <TopBarLabelStyled>{groupLabel}</TopBarLabelStyled>
          </div>
          {leftRender}
        </TopBarLeftLegendWrapperStyled>
        <TopBarCenterWrapperStyled $width={centerWidth}>
          {centerRender}
        </TopBarCenterWrapperStyled>
        <TopBarRightLegendWrapperStyled $width={rightLegendWidth}>
          {rightRender}
        </TopBarRightLegendWrapperStyled>
      </CollapsibleGroupHeaderStyled>
      <CollapsibleGroupContentWrapperStyled $height={height} $isOpen={isOpen}>
        <div ref={setRef}>{children}</div>
      </CollapsibleGroupContentWrapperStyled>
    </CollapsibleGroupStyled>
  );
};

export default CollapsibleGroup;
