import { styled, css } from 'styled-components';
import { Utils, Theme, device } from 'team-hero-ui';

import { ITableRow } from 'interfaces/Table/DataTableRow.interface';
import { getGridColsWidth } from 'components/Table/components/util';
import { TTableStyleValue } from 'components/Table/Table.types';

const { pxToRem, flexFn } = Utils;

interface IStyledTableBody {
  $styleType?: TTableStyleValue;
}

interface IStyledTableCellProps extends IStyledTableBody {
  $isFirst: boolean;
  $disabled?: boolean;
  $autoHeight?: boolean;
  $firstChildBorderRadius?: boolean;
}

interface ITableMinProps {
  isMin: boolean;
  styleType?: TTableStyleValue;
}

const getBackgroundColor = (
  isFirst = false,
  disabled = false,
  $styleType?: TTableStyleValue
): string => {
  if (isFirst) {
    return 'inherit';
  }

  if (disabled) {
    if ($styleType === 'modal') {
      return Theme.colors.greyShades.grey15;
    }

    if ($styleType === 'modalBright') {
      return Theme.colors.primary.white;
    }

    return Theme.colors.greyShades.grey6;
  }

  return Theme.colors.primary.white;
};

interface ITruncatedCellProps {
  $disabled?: boolean;
}

export const TruncatedCellStyled = styled.div<ITruncatedCellProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${pxToRem(14)};
  margin-right: ${pxToRem(4)};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.primary.grey : 'inherit'};

  @media ${device.sm} {
    margin-right: ${pxToRem(20)};
  }
`;

export const StyledTableCell = styled.div<IStyledTableCellProps>`
  ${flexFn('flex-start')}
  min-height: ${pxToRem(49)};
  font-size: ${pxToRem(14)};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.greyShades.grey4 : theme.colors.primary.grey};
  background-color: ${({ $isFirst, $disabled, $styleType }) =>
    getBackgroundColor($isFirst, $disabled, $styleType)};
  ${({ $firstChildBorderRadius }) =>
    $firstChildBorderRadius
      ? css`
          border-top-left-radius: ${pxToRem(5)};
          border-bottom-left-radius: ${pxToRem(5)};
        `
      : ''}};
  
  &:last-child {
    border-radius: ${pxToRem([0, 5, 5, 0])};
  }
`;

export const StyledTableBodyRow = styled.div<ITableMinProps>`
  position: relative;
  font-size: ${pxToRem(14)};
  border-radius: ${pxToRem(5)};
  cursor: pointer;
  margin-bottom: ${pxToRem(4)};
  transition: all 0.05s ease-in;

  .more-icon-row {
    svg .cls-2 {
      fill: ${({ theme }) => theme.colors.primary.white};
      pointer-events: none;
    }
  }

  &:hover {
    .more-icon-row {
      svg .cls-2 {
        fill: ${({ theme }) => theme.colors.greyShades.grey4};
      }
    }
    box-shadow: ${({ isMin }) =>
      isMin ? 'none' : `${pxToRem([0, 0, 17, -10])} rgb(0 0 0 / 90%)`};
  }
`;

export const StyledTableBodyInnerRow = styled.div<ITableRow>`
  display: grid;
  position: relative;
  grid-template-columns: ${({ columns }) => getGridColsWidth(columns)};
`;
