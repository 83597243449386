import moment from 'moment';

import {
  TResolutionType,
  TTimelineSize,
} from 'components/Timeline/interfaces/timelineResolutions.interface';

export const calculateResolution = (
  timelineSize: TTimelineSize
): TResolutionType => {
  if (timelineSize === '1day') {
    return 'hour';
  }
  return 'day';
};

export const calculateIncrementResolution = (
  timelineSize: TTimelineSize
): moment.unitOfTime.DurationConstructor => {
  switch (timelineSize) {
    case '1day':
      return 'day';
    case '1week':
    case '2weeks':
      return 'week';
    case '1month':
      return 'month';
    default:
      return 'day';
  }
};

export const calculateTimeLineStart = (
  timelineSize: TTimelineSize,
  start: moment.Moment
): moment.Moment => {
  switch (timelineSize) {
    case '1day':
      return start.clone().startOf('day');
    case '1week':
    case '2weeks':
      return start.clone().startOf('isoWeek');
    case '1month':
      return start.clone().startOf('month');
    default:
      return start.clone();
  }
};

export const calculateTimeLineEnd = (
  timelineSize: TTimelineSize,
  start: moment.Moment
): moment.Moment => {
  switch (timelineSize) {
    case '1year':
      return start.clone().add(1, 'year');
    case '1day':
      return start.clone().add(1, 'day');
    case '1week':
      return start.clone().add(1, 'week');
    case '2weeks':
      return start.clone().add(2, 'weeks');
    case '1month':
    default:
      return start.clone().add(1, 'month');
  }
};
