import { Icons, IToolbarConfig, Theme } from 'team-hero-ui';

export const toolbarConfig: IToolbarConfig[] = [
  {
    group: [
      {
        icon: (
          <Icons.BoldTextIcon
            svgColor={Theme.colors.primary.grey}
            svgSize={24}
          />
        ),
        style: 'left',
        type: 'BOLD',
      },
      {
        icon: (
          <Icons.ItalicTextIcon
            svgColor={Theme.colors.primary.grey}
            svgSize={24}
          />
        ),
        style: 'right',
        type: 'ITALIC',
      },
    ],
    groupKey: 'group1',
  },
  {
    group: [
      {
        icon: (
          <Icons.ListIcon svgColor={Theme.colors.primary.grey} svgSize={24} />
        ),
        style: 'none',
        type: 'unordered-list-item',
      },
    ],
    groupKey: 'group2',
  },
  {
    group: [
      {
        icon: (
          <Icons.LinkTextIcon
            svgColor={Theme.colors.primary.grey}
            svgSize={24}
          />
        ),
        style: 'none',
        type: 'LINK',
      },
    ],
    groupKey: 'group3',
  },
];
