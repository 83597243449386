import { FC } from 'react';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { IProject } from 'interfaces/Project.interface';
import { IWorkPlannerSettingState } from 'components/WorkPlanner/WorkPlanner.interface';
import { calculateTimeLineEnd } from 'components/Timeline/helper/timelineSettings.helper';
import WorkPlannerTimelineView from 'components/WorkPlanner/components/WorkPlannerTimelineView';
import WorkPlannerTable from 'components/WorkPlanner/components/WorkPlannerTable';

interface IWorkPlannerBodyProps {
  workPlannerSettings: IWorkPlannerSettingState;
  project: IProject;
  mission: IProjectMission;
}

const WorkPlannerBody: FC<IWorkPlannerBodyProps> = ({
  workPlannerSettings,
  mission,
  project,
}) => {
  switch (workPlannerSettings.viewMode) {
    case 'timeline':
      return (
        <WorkPlannerTimelineView
          timelineSize={workPlannerSettings.timelineSize}
          timelineStart={workPlannerSettings.startDate}
          timelineEnd={calculateTimeLineEnd(
            workPlannerSettings.timelineSize,
            workPlannerSettings.startDate
          )}
          mission={mission}
          project={project}
        />
      );
    case 'list':
      return (
        <WorkPlannerTable
          start={workPlannerSettings.startDate}
          end={calculateTimeLineEnd(
            workPlannerSettings.timelineSize,
            workPlannerSettings.startDate
          )}
          mission={mission}
        />
      );
    default:
      return <div>this mode is not enabled yet</div>;
  }
};

export default WorkPlannerBody;
