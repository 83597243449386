import { TFunction } from 'i18next';

import { IFilterDefinition } from 'interfaces/Table/DataTableFilters.interface';

interface IProjectShiftFiltersFactoryProps {
  t: TFunction;
}

export const projectShiftFiltersFactory = ({
  t,
}: IProjectShiftFiltersFactoryProps): IFilterDefinition[] => [
  {
    key: 'position',
    label: t('filter.label.position'),
    filterType: 'input',
  },
  {
    key: 'area',
    label: t('filter.label.area'),
    filterType: 'input',
  },
];
