import { FC, useMemo, useState } from 'react';
import moment from 'moment';

import {
  IMissionTimetrackingSettingState,
  IMissionTimetrackingsTabProps,
} from './MissionTimetrackingsTab.types';
import { useGetProjectMissionItemQuery } from 'services/teamHeroApi.service';
import { TimetrackingListTable } from './TimetrackingList/TimetrackingList';
import RangeTimelineControls from 'components/RangeTimelineControls';

const MissionTimetrackingsTab: FC<IMissionTimetrackingsTabProps> = ({
  filterPropertyName,
  id,
  projectId,
}) => {
  const isMissionFilter = filterPropertyName === 'shift.mission';

  const { currentData: missionData } = useGetProjectMissionItemQuery(
    { id },
    { skip: !isMissionFilter }
  );

  const startDate = missionData?.start
    ? moment(missionData?.start)
    : moment().startOf('isoWeek');

  const endDate = missionData?.start
    ? moment(missionData?.start).add(1, 'week')
    : startDate.clone().endOf('isoWeek');

  const defaultWorkPlannerSettingsState: IMissionTimetrackingSettingState = {
    startDate,
    endDate,
    timelineSize: '1week',
    viewMode: 'list',
  };

  const [timetrackingSettings, setTimetrackingSettings] =
    useState<IMissionTimetrackingSettingState>(defaultWorkPlannerSettingsState);

  const centerHeader = useMemo(
    () => (
      <RangeTimelineControls
        timelineSettings={timetrackingSettings}
        setTimelineSettings={(settings) => setTimetrackingSettings(settings)}
      />
    ),
    [timetrackingSettings]
  );

  return (
    <TimetrackingListTable
      id={id}
      projectId={projectId}
      filterPropertyName={filterPropertyName}
      timetrackingSettings={timetrackingSettings}
      timetrackingCenterHeader={centerHeader}
    />
  );
};

export default MissionTimetrackingsTab;
