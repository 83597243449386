import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Icons, Theme } from 'team-hero-ui';

import { useDateFormat } from 'hooks/useDateFormat.hook';
import LocalizedDatePicker from 'components/LocalizedDatePicker';
import { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import { IWorkPlannerSettingState } from 'components/WorkPlanner/WorkPlanner.interface';
import TimelineSizeDropdown from 'components/Timeline/components/TimelineSizeDropdown';
import useTimelineControls from 'hooks/useTimelineControls.hook';

interface ITimelineControlsProps {
  timelineSettings: IWorkPlannerSettingState;
  setTimelineSettings: Dispatch<SetStateAction<IWorkPlannerSettingState>>;
}

const TimelineControls: FC<ITimelineControlsProps> = ({
  timelineSettings,
  setTimelineSettings,
}) => {
  const { dayShortWithDate } = useDateFormat();
  const timelineSizeItems: TTimelineSize[] = [
    '1day',
    '1week',
    '2weeks',
    '1month',
  ];

  const {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleSetTimelineSize,
    handleStartDateChange,
    handleOnClickToday,
  } = useTimelineControls({ setTimelineSettings });

  return (
    <>
      <Button onClick={handleOnClickToday} data-test-id='timeline-today-button'>
        <Icons.TargetIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={21}
        />
      </Button>
      <TimelineSizeDropdown
        value={timelineSettings.timelineSize}
        handleChange={handleSetTimelineSize}
        timelineSizeItems={timelineSizeItems}
      />
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.startDate.toDate()}
        onChange={(value) => handleStartDateChange(value)}
        clickHandlerLeftArrow={handleOnClickLeftArrow}
        clickHandlerRightArrow={handleOnClickRightArrow}
        format={
          timelineSettings.timelineSize === '1day'
            ? dayShortWithDate
            : undefined
        }
      />
    </>
  );
};

export default TimelineControls;
