import {
  BaseQueryFn,
  QueryDefinition,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IContact } from 'interfaces/Contact.interface';
import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IGetContactDocumentsCollectionArgs,
  IGetContactDocumentsCollectionResponse,
  IPutContactDocumentArgs,
} from './contactDocuments.api.type';

const defaultTotalCount = 0;
const defaultItems: IContact[] = [];

export const getContactDocuments = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetContactDocumentsCollectionArgs,
  BaseQueryFn,
  string,
  IGetContactDocumentsCollectionResponse
> =>
  build.query<
    IGetContactDocumentsCollectionResponse,
    IGetContactDocumentsCollectionArgs
  >({
    providesTags: ['ContactDocument'],
    query: (queryArg: IGetContactDocumentsCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/contact_documents${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactDocument>
    ): IGetContactDocumentsCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IContactDocument[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const putContactDocument = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutContactDocumentArgs,
  BaseQueryFn,
  string,
  IContactDocument
> =>
  build.mutation<IContactDocument, IPutContactDocumentArgs>({
    invalidatesTags: ['ContactDocument'],
    query: (queryArg) => ({
      url: `api/contact_documents/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
