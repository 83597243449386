import { useCallback, useEffect } from 'react';

import type { IContact } from 'interfaces/Contact.interface';
import useScript from './useScript.hook';
import { useToggle } from './useToggle.hook';

interface IUserSnapInitOptions {
  custom?: {
    [key: string]: string;
  };
  user?: {
    userId: string;
    email: string;
  };
  collectGeoLocation?: 'none' | 'all';
  useLocalStorage?: boolean;
  useSystemFonts?: boolean;
}

interface IUserSnapCustomEvent extends Event {
  init: (initOptions?: IUserSnapInitOptions) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (e: string, callback: (event: any) => void) => void;
  show: (apiKey: string) => void;
}

interface IUseUserSnapReturn {
  activateUserSnap: (contact?: IContact) => void;
  deactivateUserSnap: () => void;
}

const useUserSnap = (): IUseUserSnapReturn => {
  const {
    toggleValue: scriptEnabled,
    on: setScriptEnabled,
    off: setScriptDisabled,
  } = useToggle(false);

  const usersnapGlobalApiKey = import.meta.env.VITE_USERSNAP_GLOBAL_API_KEY;

  const userSnapScriptStatus = useScript(
    `https://widget.usersnap.com/global/load/${usersnapGlobalApiKey}?onload=onUsersnapCXLoad`,
    scriptEnabled && !!usersnapGlobalApiKey
  );

  useEffect(() => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info(`UserSnapScriptStatus: ${userSnapScriptStatus}`);
    }
  }, [userSnapScriptStatus]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setUserOnOpen = useCallback((event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event.api.setValue('visitor', (<any>window).userEmail);
  }, []);

  const activateUserSnap = useCallback(() => {
    if (!!usersnapGlobalApiKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).onUsersnapCXLoad = (api: IUserSnapCustomEvent) => {
        api.init({
          collectGeoLocation: 'none',
          useLocalStorage: false,
          useSystemFonts: true,
        });

        api.on('open', setUserOnOpen);
      };

      setScriptEnabled();
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'No USERSNAP GLOBAL API KEY defined. Please check your environment and set it'
      );
    }
  }, [setUserOnOpen, usersnapGlobalApiKey, setScriptEnabled]);

  return {
    activateUserSnap,
    deactivateUserSnap: setScriptDisabled,
  };
};

export default useUserSnap;
