import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ConversationListWrapperStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  overflow: auto;
  height: 100%;
  position: relative;
  padding: ${pxToRem(20)};
  background-color: ${Theme.colors.primary.white};
`;

export const ConversationListItemsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(8)};
  flex-grow: 1;
  overflow: auto;
  height: ${pxToRem(40)};
`;

export const ConversationListStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: 100%;
  background-color: ${Theme.colors.greyShades.grey6};
`;

export const ConversationItemStyled = styled.div`
  cursor: pointer;
`;
