import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Timeline from 'components/Timeline';
import { calculateResolution } from 'components/Timeline/helper/timelineSettings.helper';
import { useContactCompare } from 'components/WorkPlanner/hooks/useContactCompare.hook';
import { getItemsAndGroupsFromShiftsByKey } from 'components/Timeline/helper/shiftTimeline.helper';
import { TimelineItemProjectShift } from 'components/WorkPlanner/components/WorkPlannerTimelineView/components/itemRenderer';
import { ShiftLeftLegend } from 'components/WorkPlanner/components/WorkPlannerTimelineView/components/legendRenderer/LeftLegend';
import { ShiftRightLegend } from 'components/WorkPlanner/components/WorkPlannerTimelineView/components/legendRenderer/RightLegend';
import { ITimelineShiftViewProps } from './TimelineShiftView.type';
import GroupWrapper from '../TimelineGroupView/components/GroupWrapper';

const TimelineShiftView: FC<ITimelineShiftViewProps> = ({
  group,
  shifts,
  timelineWidth,
  timelineSize,
  timelineStart,
  timelineEnd,
  leftLegendWidth,
  rightLegendWidth,
  mainGroupKey,
  subGroupKey,
  missionId,
}) => {
  const { t } = useTranslation('general');
  const shiftRowHeight = 105;

  const groupLabel = t(`workplanner.timeline.grouplabel.${mainGroupKey}`, {
    groupLabel: group.label,
  });

  const { groups, items } = getItemsAndGroupsFromShiftsByKey(
    shifts || [],
    subGroupKey
  );

  const { contactToCompare, toggleCompareWithContact: onChooseContact } =
    useContactCompare();

  const resolution = calculateResolution(timelineSize);

  return (
    <>
      <GroupWrapper
        start={timelineStart}
        end={timelineEnd}
        shiftItems={shifts}
        timelineWidth={timelineWidth}
        leftLegendWidth={leftLegendWidth}
        rightLegendWidth={rightLegendWidth}
        timelineSize={timelineSize}
        open
        groupLabel={groupLabel}
      >
        <Timeline
          timelineWidth={timelineWidth}
          resolution={resolution}
          timelineStart={timelineStart}
          timelineEnd={timelineEnd}
          leftLegendWidth={leftLegendWidth}
          rightLegendWidth={rightLegendWidth}
          items={items}
          groups={groups}
          rowHeight={shiftRowHeight}
          groupKey={subGroupKey}
          missionId={missionId}
          ItemRenderer={(props) => (
            <TimelineItemProjectShift
              {...props}
              contactToCompare={contactToCompare}
              missionId={missionId}
            />
          )}
          LeftLegendRenderer={(props) => (
            <ShiftLeftLegend
              {...props}
              contactToCompare={contactToCompare}
              onChooseContact={onChooseContact}
            />
          )}
          RightLegendRenderer={ShiftRightLegend}
        />
      </GroupWrapper>
    </>
  );
};

export default TimelineShiftView;
