import {
  IClientLoginDashboardApplicationsPendingWidget,
  IClientLoginDashboardRatingsPendingWidget,
  IClientLoginDashboardTimetrackingsPendingWidget,
  IWidget,
} from 'interfaces/Widget/Widget.interface';
import { useGetWidgetsQuery } from 'services/teamHeroApi.service';

function getWidgetDataByKey<T>(key: string, widgetData?: IWidget<unknown>[]) {
  return widgetData?.find((item) => item.key === key)?.data as T | undefined;
}

interface IUseGetClientLoginDashboardWidgetReturn {
  applicationsPendingCount: number;
  ratingsPendingCount: number;
  timetrackingsPendingCount: number;
  isLoading: boolean;
  isError: boolean;
  timetrackingsPendingByMissionId: (missionId: number) => number;
  applicationsPendingByMissionId: (missionId: number) => number;
  ratingsPendingByMissionId: (missionId: number) => number;
}

interface IUseGetClientLoginDashboardWidgetProps {
  skip?: boolean;
}

const useGetClientLoginDashboardWidget = ({
  skip = false,
}: IUseGetClientLoginDashboardWidgetProps): IUseGetClientLoginDashboardWidgetReturn => {
  const {
    data: widgetsData,
    isLoading,
    isError,
  } = useGetWidgetsQuery(
    {
      filters: [
        {
          key: 'group',
          operator: 'AND',
          value: 'dashboard-client-login',
        },
      ],
    },
    { skip }
  );

  const applicationsPending =
    getWidgetDataByKey<IClientLoginDashboardApplicationsPendingWidget>(
      'dashboard-applications-pending',
      widgetsData?.items
    );

  const ratingsPending =
    getWidgetDataByKey<IClientLoginDashboardRatingsPendingWidget>(
      'dashboard-ratings-pending',
      widgetsData?.items
    );

  const timetrackingsPending =
    getWidgetDataByKey<IClientLoginDashboardTimetrackingsPendingWidget>(
      'dashboard-timetrackings-pending',
      widgetsData?.items
    );

  const applicationsPendingByMissionId = (missionId: number): number => {
    return applicationsPending?.missions[missionId] || 0;
  };

  const ratingsPendingByMissionId = (missionId: number): number => {
    return ratingsPending?.missions[missionId] || 0;
  };

  const timetrackingsPendingByMissionId = (missionId: number): number => {
    return timetrackingsPending?.missions[missionId] || 0;
  };

  return {
    applicationsPendingCount: applicationsPending?.total || 0,
    ratingsPendingCount: ratingsPending?.total || 0,
    timetrackingsPendingCount: timetrackingsPending?.total || 0,
    isLoading,
    isError,
    applicationsPendingByMissionId,
    ratingsPendingByMissionId,
    timetrackingsPendingByMissionId,
  };
};

export default useGetClientLoginDashboardWidget;
