import { FC, useEffect, useRef, useState, useCallback } from 'react';
import { Pagination } from 'team-hero-ui';

import { Dropdown } from './components/Dropdown';
import {
  DropdownWrapperStyled,
  PaginationFooterStyled,
  PaginationWrapperStyled,
} from './PaginationFooter.styled';
import type { IPaginationFooterProps } from './PaginationFooter.types';

const PaginationFooter: FC<IPaginationFooterProps> = ({
  dropdownProps,
  paginationProps,
}) => {
  const footerContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const wrapContainerMinLimit = 500;

  const handleResize = useCallback(() => {
    if (footerContainerRef.current) {
      const boundingRect = footerContainerRef.current.getBoundingClientRect();
      const { width } = boundingRect;

      if (width !== containerWidth) {
        setContainerWidth(width);
      }
    }
  }, [containerWidth]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [footerContainerRef, handleResize]);

  return (
    <PaginationFooterStyled
      ref={footerContainerRef}
      wrapFooterElements={containerWidth < wrapContainerMinLimit}
    >
      {!!dropdownProps && (
        <DropdownWrapperStyled>
          <Dropdown<number>
            items={dropdownProps.items}
            selected={dropdownProps.selected}
            onChange={dropdownProps.onChange}
          />
        </DropdownWrapperStyled>
      )}
      <PaginationWrapperStyled $withPagesDropdown={!!dropdownProps}>
        <Pagination {...paginationProps} />
      </PaginationWrapperStyled>
    </PaginationFooterStyled>
  );
};

export default PaginationFooter;
